import { Box } from "@chakra-ui/react";

const SpecificProductBlock = ({
  specificProduct,
  isListItemView,
}: {
  specificProduct: any;
  isListItemView?: boolean;
}) => (
  <>
    {specificProduct?.map((val: any, index: number) => (
      <Box key={index}>
        {" "}
        {isListItemView && "-"} {val?.productInfo?.title}
      </Box>
    )) || (
      <>
        {isListItemView && "-"} {specificProduct?.productInfo?.title}
      </>
    )}
  </>
);

export default SpecificProductBlock;
