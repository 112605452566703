import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Condition, EmptySlotOption } from "../../models";
import {
  createCondition,
  createVariants,
  deleteCondition,
  deleteVariants,
  getConditionById,
  updateCondition as updateSpecificCondition,
  updateConditions,
  updateVariants,
  updateSlotConfiguration,
} from "../../services/conditions.service";
import { RootState } from "../store";
import { fetchConditionsAndSlotsAsync } from "./appSlice";

export enum Overview {
  CONDITION_VIEW = "CONDITION_VIEW",
  VARIANT_VIEW = "VARIANT_VIEW",
  ADD_CONDITION_VIEW = "ADD_CONDITION_VIEW",
  EDIT_CONDITION_VIEW = "EDIT_CONDITION_VIEW",
}

interface ConditionState {
  conditions: Condition[];
  error: string | null;
  selectedConditionId: string | null;
  loading: boolean;
  overview: Overview;
  selectedVariantId: string | null;
  loadingCondition: boolean;
  conditionError: string | null;
  updatingConditions: boolean;
}

const initialState: ConditionState = {
  conditions: [],
  error: null,
  selectedConditionId: null,
  loading: false,
  overview: Overview.CONDITION_VIEW,
  selectedVariantId: null,
  loadingCondition: false,
  conditionError: null,
  updatingConditions: false,
};

export const selectConditionAsync = createAsyncThunk("conditon/fetchCondition", async (id: string, { getState }) => {
  return getConditionById(id);
});

export const updateConditionsAsync = createAsyncThunk(
  "conditions/update",
  async (conditions: { id: string; priority: number }[]) => {
    const response = await updateConditions(conditions);

    if (!response.success) {
      throw new Error(response.message || "Error in updating condition");
    }

    return {
      ...response,
    };
  },
);

export const updateConditionAsync = createAsyncThunk("condition/update", async (data: { id: string; body: any }) => {
  const response = await updateSpecificCondition(data.id, data.body);

  if (!response.success) {
    throw new Error(response.message || "Error in updating condition");
  }

  return {
    ...response,
    ...data,
  };
});

export const createConditionAsync = createAsyncThunk("condition/create", async (body: any) => {
  const response = await createCondition(body);

  if (!response.success) {
    throw new Error(response.message || "Error in creating condition");
  }

  return response;
});

export const deleteConditionAsync = createAsyncThunk("condition/delete", async (id: string) => {
  const response = await deleteCondition(id);

  if (!response.success) {
    throw new Error(response.message || "Error in deleting condition");
  }

  return { ...response, id };
});

export const createVariantAsync = createAsyncThunk("variant/create", async (body: any) => {
  const response = await createVariants(body);

  if (!response.success) {
    throw new Error(response.message || "Error in creating variant");
  }

  return {
    ...response,
  };
});

export const updateVariantAsync = createAsyncThunk("variant/update", async (data: { id: string; body: any }) => {
  const response = await updateVariants(data.id, data.body);

  if (!response.success) {
    throw new Error(response.message || "Error in updating variant");
  }

  return {
    ...response,
    ...data,
  };
});

export const deleteVariantAsync = createAsyncThunk("variant/delete", async (id: string) => {
  const response = await deleteVariants(id);

  if (!response.success) {
    throw new Error(response.message || "Error in deleting variant");
  }

  return { ...response, id };
});

export const updateSlotConfigurationAsync = createAsyncThunk(
  "slots/configuration",
  async (data: { id: string; body: any }) => {
    const response = await updateSlotConfiguration(data.id, data.body);

    if (!response.success) {
      throw new Error(response.message || "Error in updating slotConfig");
    }

    return {
      ...response,
      ...data,
    };
  },
);

const conditionSlice = createSlice({
  name: "conditions",
  initialState,
  reducers: {
    selectCondition: (state, action: PayloadAction<string | null>) => {
      state.selectedConditionId = action.payload;
      const selectedCondition = state.conditions.find(condition => condition.id === action.payload);

      state.selectedVariantId = null;

      if (selectedCondition) {
        state.overview = !selectedCondition?.isDefault ? Overview.EDIT_CONDITION_VIEW : Overview.CONDITION_VIEW;
      }
    },
    updateCondition: (
      state,
      action: PayloadAction<{ id: string; status?: boolean; name?: string; rule?: any; priority?: number }>,
    ) => {
      state.conditions = state.conditions.map(condition => {
        if (condition.id === action.payload.id) {
          return {
            ...condition,
            status: action.payload.status !== undefined ? action.payload.status : condition.status,
            name: action.payload.name || condition.name,
            rule: action.payload.rule || condition.rule,
            priority: action.payload.priority || condition.priority,
          };
        }

        return condition;
      });
    },
    updateConditionsBasedOnPriority: (state, action: PayloadAction<{ conditions: Condition[] }>) => {
      state.conditions = action?.payload?.conditions;
    },
    updateVariant: (state, action: PayloadAction<{ id: string; status?: boolean; name?: string; rule?: any }>) => {
      const selectedCondition = state.conditions.find(condition => condition.id === state.selectedConditionId);
      const selectedVariant = selectedCondition?.variants?.find(variant => variant.id === action.payload.id);

      if (selectedVariant) {
        selectedVariant.name = action.payload.name !== undefined ? action.payload.name : selectedVariant.name;
        selectedVariant.status = action.payload.status !== undefined ? action.payload.status : selectedVariant.status;
      }
    },
    setOverview: (state, action: PayloadAction<Overview>) => {
      state.overview = action.payload;

      if (action.payload === Overview.ADD_CONDITION_VIEW) {
        state.selectedConditionId = null;
        state.selectedVariantId = null;
      }
    },
    setSelectedVariantId: (state, action: PayloadAction<string | null>) => {
      state.selectedVariantId = action.payload;

      if (state.selectedVariantId) {
        state.overview = Overview.VARIANT_VIEW;
      } else {
        state.overview = Overview.CONDITION_VIEW;
      }
    },
    setUpdatingConditions: (state, action: PayloadAction<boolean>) => {
      state.updatingConditions = action.payload;
    },
    updateSlotConfig: (
      state,
      action: PayloadAction<{ id: string; name?: string; emptySlotOption?: EmptySlotOption }>,
    ) => {
      const { id, emptySlotOption } = action.payload;
      const selectedConditionId = state.selectedConditionId;

      const conditionIndex = state.conditions.findIndex(condition => condition.id === selectedConditionId);

      if (conditionIndex >= 0) {
        const selectedCondition = state.conditions[conditionIndex];

        if (selectedCondition.slotConfig) {
          const slotConfigIndex = selectedCondition.slotConfig.findIndex(slotConfig => slotConfig.id === id);

          if (slotConfigIndex >= 0) {
            const slotConfig = selectedCondition.slotConfig[slotConfigIndex];

            selectedCondition.slotConfig[slotConfigIndex] = {
              ...slotConfig,
              emptySlotOption: emptySlotOption ?? slotConfig.emptySlotOption,
            };
          }
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchConditionsAndSlotsAsync.pending, state => {
        state.loading = true;
        state.overview = Overview.CONDITION_VIEW;
      })
      .addCase(fetchConditionsAndSlotsAsync.fulfilled, (state, action: PayloadAction<any>) => {
        const conditionsRes = action.payload.conditions;

        if (conditionsRes?.success) {
          state.conditions = conditionsRes.data;
          // If there is no condition then create default condition action will be triggered and that will make the loading false once completed
          state.loading = state.conditions?.length === 0;
        } else {
          state.error = conditionsRes.error;
          state.loading = false;
        }
      })
      .addCase(fetchConditionsAndSlotsAsync.rejected, (state, error) => {
        state.error = error?.error?.message || "";
        state.loading = false;
      })
      .addCase(updateConditionAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const index = state.conditions.findIndex((condition: Condition) => condition.id === action.payload.id);

          state.conditions[index] = {
            ...state.conditions[index],
            ...action.payload.body,
          };
        }
      })
      .addCase(createConditionAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          // When created condition is default condition, it will set the loading false
          if (action.payload.data?.isDefault === true) {
            state.conditions = [action.payload.data];
            state.selectedConditionId = action.payload.data.id;
            state.loading = false;
            return;
          }

          state.conditions.unshift(action.payload.data);
          state.loading = false;
        }
      })
      .addCase(selectConditionAsync.pending, (state, action: PayloadAction<any>) => {
        state.loadingCondition = true;
        state.conditionError = null;
      })
      .addCase(selectConditionAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const index = state.conditions.findIndex((condition: Condition) => condition.id === action.payload.data.id);

          state.conditions[index] = {
            ...state.conditions[index],
            ...action.payload.data,
          };
        } else {
          state.conditionError = action.payload.message;
        }

        state.loadingCondition = false;
      })
      .addCase(deleteConditionAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const index = state.conditions.findIndex((condition: Condition) => condition.id === action.payload.id);

          state.conditions.splice(index, 1);
        }
      })
      .addCase(createVariantAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const selectedCondition = state.conditions.find(condition => condition.id === state.selectedConditionId);

          if (!selectedCondition) {
            return;
          }

          if (!selectedCondition.variants) {
            selectedCondition.variants = [];
          }

          if (selectedCondition) {
            selectedCondition.variants.push(action.payload.data);
          }
        }
      })
      .addCase(updateVariantAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const selectedCondition = state.conditions.find(condition => condition.id === state.selectedConditionId);

          if (selectedCondition?.variants) {
            const selectedVariantIndex = selectedCondition?.variants?.findIndex(
              variant => variant.id === action.payload.response.id,
            );

            if (selectedVariantIndex > -1) {
              selectedCondition.variants[selectedVariantIndex] = {
                ...selectedCondition.variants[selectedVariantIndex],
                ...action.payload.response,
                widgets: selectedCondition?.variants[selectedVariantIndex]?.widgets,
              };
            }
          }
        }
      })
      .addCase(deleteVariantAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const deletedVariantId = action.payload.id;
          const selectedCondition = state.conditions.find(condition => condition.id === state.selectedConditionId);

          if (selectedCondition) {
            selectedCondition.variants = selectedCondition.variants?.filter(variant => variant.id !== deletedVariantId);
            state.overview = !selectedCondition?.isDefault ? Overview.EDIT_CONDITION_VIEW : Overview.CONDITION_VIEW;
          }
        }
      });
  },
});

export const selectConditionsState = (state: RootState) => state.conditions;

export const {
  selectCondition,
  updateCondition,
  updateVariant,
  setOverview,
  setUpdatingConditions,
  setSelectedVariantId,
  updateConditionsBasedOnPriority,
  updateSlotConfig,
} = conditionSlice.actions;

export default conditionSlice.reducer;
