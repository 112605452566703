import { Box, Button, Divider, Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { PageType } from "@reconvert/reconvert-utils";
import { EmptySlotOption, Slot } from "../../models";
import SlotHeader from "../SlotHeader/SlotHeader";
import { useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../app/slice/appSlice";
import useConditions from "../../hooks/useConditions";
import { useEffect, useMemo, useState } from "react";
import useUnsavedBar, { PreventActionType, PreventActionTypeObject } from "../../hooks/useUnsavedBar";

const EmptyState = ({
  slot,
  hideHeader,
  onAddWidget,
  isLoading,
  onSlotDelete,
}: {
  slot: Slot;
  hideHeader?: boolean;
  onAddWidget: any;
  isLoading: boolean;
  onSlotDelete: () => void;
}) => {
  const { t } = useTranslation();
  const { activePage } = useAppSelector(selectAppState);
  const { preventAction } = useUnsavedBar();
  const { selectedCondition, handleUpdateSlotConfiguration } = useConditions();
  const isPOPPages = [PageType.POST_PURCHASE_PAGE1, PageType.POST_PURCHASE_PAGE2].includes(activePage!);
  const [emptySlotOption, setEmptySlotOption] = useState<EmptySlotOption>("CONTINUE");
  const slotConfig = useMemo(() => {
    return selectedCondition?.slotConfig?.find(config => config.slotId === slot.id);
  }, [selectedCondition?.slotConfig, slot.id]);

  useEffect(() => {
    if (slotConfig) {
      setEmptySlotOption(slotConfig.emptySlotOption);
    }
  }, [slotConfig]);

  return (
    <>
      {!hideHeader && <SlotHeader slot={slot} />}
      <Box mx={4}>
        <Box
          sx={{
            display: "flex",
            padding: "20px 60px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "2px dashed",
            borderColor: "neutrals.500",
          }}
        >
          <Typography maxWidth="100%" variant="body" fontWeight={500} textAlign={"center"} color="neutrals.600">
            {t("When slot is empty:")}
          </Typography>

          <Box mt="8px">
            <RadioGroup
              onChange={value => {
                preventAction(() => {
                  if (slotConfig?.id) {
                    handleUpdateSlotConfiguration(slotConfig?.id, emptySlotOption, value as EmptySlotOption);
                  }
                }, PreventActionTypeObject.INLINE_CHANGE as PreventActionType);
              }}
              value={emptySlotOption}
            >
              <Stack direction="row">
                <Radio value="CONTINUE">
                  <Typography variant="small" fontWeight={400} color="neutrals.900">
                    {t("Continue to the next condition")}
                  </Typography>
                </Radio>
                <Radio value="STOP">
                  <Typography variant="small" fontWeight={400} color="neutrals.900">
                    {t("Keep the slot empty")}
                  </Typography>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <Divider w="85%" my="14px" />
          <Flex gap="14px">
            <Button
              variant="secondary"
              size={"sm"}
              onClick={() => preventAction(() => onAddWidget())}
              isLoading={isLoading}
              fontSize={16}
              px={4}
              fontWeight={500}
            >
              {t("Add widgets")}
            </Button>

            {!isPOPPages && (
              <Button
                variant="ghost"
                size={"sm"}
                onClick={onSlotDelete}
                fontSize={16}
                px={4}
                fontWeight={500}
                color="primary.800"
              >
                {t("Delete slot")}
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default EmptyState;
