import { Box, Flex, Input } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import React from "react";
import { useTranslation } from "react-i18next";

interface ProductTitleSelectorProps {
  titleText: string;
  value: string;
  setTitleValue: (data: { value: string }) => void;
}

const ProductTextSelector = ({ titleText, value, setTitleValue }: ProductTitleSelectorProps) => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Flex gap={1} flexDirection={"column"}>
        <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
          {t(titleText)}
        </Typography>
        <Input value={value} onChange={e => setTitleValue({ value: e.target.value })} />
      </Flex>
    </Box>
  );
};

export default ProductTextSelector;
