import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getVariant } from "../../../services/conditions.service";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { Icons, Typography } from "@reconvert/react-ui-component";
import { Condition } from "../../../models";
import { ConditionFact } from "@reconvert/reconvert-utils";

interface ConditionTooltipProps {
  condition: Condition;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  mouseRef: MutableRefObject<any>;
}

const ConditionTooltip = ({ condition, setIsOpen, mouseRef }: ConditionTooltipProps) => {
  const renderProduct = () => {
    switch (condition?.rule?.all[0]?.fact) {
      case ConditionFact.SPECIFIC_PRODUCT:
        return (
          <Product
            id={condition?.id}
            productIds={condition?.rule?.all[0]?.extraFields?.productIds}
            variantList={condition?.rule?.all[0]?.value}
          />
        );

      case ConditionFact.PRODUCT_COLLECTION:
        return <ProductCollection productCollections={condition?.rule?.all[0]?.extraFields?.productCollections} />;

      default:
        return <></>;
    }
  };

  return (
    <Box onMouseOver={() => mouseRef.current = true} onMouseLeave={() => setIsOpen(false)}>
      {renderProduct()}
    </Box>
  );
};

const Product = ({ productIds, variantList, id }: any) => {
  const { t } = useTranslation();
  const [productToShow, setProductToShow] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (productToShow?.length === 0) {
      getVariantDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds, variantList]);

  const getVariantDetail = async () => {
    setLoading(true);

    try {
      const response = await getVariant(productIds);

      if (response) {
        setProductToShow(response);
      }
    } catch (error) {
      console.error("Error fetching variant detail:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {loading ? (
        <Flex>
          <Spinner variant={"ring"} size={"sm"} />
        </Flex>
      ) : (
        <Box maxHeight={"300px"} overflowY={"auto"}>
          {productToShow?.map((item: any, key: any) => (
            <Flex px="14px" py="14px" key={"item-" + key} justifyContent={"space-between"} alignItems={"center"}>
              <Flex gap="12px">
                {item?.images.length > 0 ? (
                  <img alt={t("product image")} src={item?.images[0] || ""} height={"36px"} width={"36px"} />
                ) : (
                  <Icons.ImagePreview w="36px" h="36px" />
                )}

                <Box>
                  <Typography lineHeight={"20px"} variant="subheading2">
                    {item?.title}
                  </Typography>
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};

const ProductCollection = ({ productCollections }: { productCollections: { id: string; name: string }[] }) => {
  return (
    <>
      <Box>
        <Box maxHeight={"300px"} overflowY={"auto"}>
          {productCollections?.map((item, key: any) => (
            <Flex px="14px" py="14px" key={"item-" + key} justifyContent={"space-between"} alignItems={"center"}>
              <Flex gap="12px">
                <Box>
                  <Typography lineHeight={"20px"} variant="subheading2">
                    {item?.name}
                  </Typography>
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ConditionTooltip;