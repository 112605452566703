import { Card } from "@chakra-ui/react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";

const EmptyCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      borderLeft={"2px solid"}
      borderColor={"divider.2"}
      bg="neutrals.300"
      mt={4}
      p={3}
      textAlign="center"
      h={20}
      borderRadius={8}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body" color="neutrals.600">
        {t("Please finish creating the condition on the right sidebar")}
      </Typography>
      <Typography variant="body" color="neutrals.600">
        {t("To start edit the slots and widgets")}
      </Typography>
    </Card>
  );
};

export default EmptyCard;
