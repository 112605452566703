import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import AppWrapper from "./AppWrapper";
import { store } from "./app/store";
import { CurrencyFormatterProvider } from "@reconvert/react-ui-component";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CurrencyFormatterProvider>
        <AppWrapper />
      </CurrencyFormatterProvider>
    </Provider>
  </React.StrictMode>,
);
