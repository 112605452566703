import { AUTH_LOGIN, AUTH_TOKEN } from "../utils/constant";
import { createRequest, postJson } from "../utils/network";

export async function login(token: string) {
  const res = await createRequest<any>(AUTH_LOGIN, { method: "GET" }, token);

  return res;
}

export async function getToken(platformStoreId: string) {
  const res = await postJson<any>(AUTH_TOKEN, { platformStoreId });

  return res;
}