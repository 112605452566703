import { Box, Button, Checkbox, Divider, Flex, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";
import { AlertDialogModal, Icons, Typography, useCurrencyFormatter } from "@reconvert/react-ui-component";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import useSearchProduct from "../../../../../hooks/useSearchProduct";
import { ProductData } from "../../../../../models";
import { useAppSelector } from "../../../../../app/hooks";
import { selectAppState } from "../../../../../app/slice/appSlice";
import { ConditionFact } from "@reconvert/reconvert-utils";

interface SearchProductModalProps {
  value: Record<string, string[]>; // <productId, variantId[]>
  setValue: (val: Record<string, string[]>) => void; // <productId, variantId[]>
  handleOnClose: () => void;
  conditionFact?: string;
}

interface FooterProps {
  onSave: () => void;
  onCancel: () => void;
  t: any;
}

interface ModalContentProps {
  searchTerm: string;
  searching: boolean;
  setSearchTerm: (val: string) => void;
  products: ProductData[];
  selectedProductIdObj: Record<string, string[]>; // <productId, variantId[]>
  handleProductSelect: (productId: string, variantIds: string[], isChecked: boolean) => void;
  conditionFact?: string
}

const SearchProductModal: React.FC<SearchProductModalProps> = ({ value, setValue, handleOnClose, conditionFact }) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  const { searching, searchTerm, setSearchTerm, products, selectedProductIdObj, handleProductSelect } =
    useSearchProduct(value || {}, conditionFact || ConditionFact.SPECIFIC_PRODUCT);

  const handleProductSave = useCallback(() => {
    setValue(selectedProductIdObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductIdObj]);

  return (
    <Box>
      <AlertDialogModal
        isOpen={true}
        bodyStyle={{
          margin: 0,
          padding: 0,
        }}
        leastDestructiveRef={modalRef}
        onClose={handleOnClose}
        footer={<Footer onSave={handleProductSave} onCancel={handleOnClose} t={t} />}
        title={""}
        size={"xl"}
        blockScrollOnMount={true}
      >
        <ModalContent
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          products={products}
          selectedProductIdObj={selectedProductIdObj}
          handleProductSelect={handleProductSelect}
          searching={searching}
          conditionFact={conditionFact}
        />
      </AlertDialogModal>
    </Box>
  );
};

const ModalContent: React.FC<ModalContentProps> = ({
  searching,
  searchTerm,
  setSearchTerm,
  products,
  selectedProductIdObj,
  handleProductSelect,
  conditionFact
}) => {
  const { t } = useTranslation();
  const { storeInfo } = useAppSelector(selectAppState);
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <>
      <Flex gap={1} flexDirection={"column"} p={3}>
        <InputGroup>
          <InputLeftElement>
            <Icons.Search color="#6A7381" />
          </InputLeftElement>
          <Input
            autoFocus={true}
            cursor={"pointer"}
            _hover={{
              border: "2px solid #6a7381",
            }}
            _focus={{
              borderColor: "#6A7381",
            }}
            placeholder={conditionFact === ConditionFact.SPECIFIC_PRODUCT ? t("Search for products") : t("Search for product collections")}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </Flex>
      <Flex overflowY={"auto"} height={"55vh"} mr={"2px"} flexDirection={"column"}>
        {searching ? (
          <Flex justifyContent={"center"} width={"100%"} alignItems={"center"} height={"100%"}>
            <Spinner />
          </Flex>
        ) : (
          <>
            {products?.length > 0 ? (
              products?.map(({ id, images, title, subTitle, variants }) => {
                let price = variants?.length >= 1 ? variants[0]?.price : "";

                return (
                  <Box key={id}>
                    <Flex
                      my="14px"
                      pl={"24px"}
                      pr="24px"
                      gap="16px"
                      cursor="pointer"
                      onClick={() => {
                        handleProductSelect(
                          id,
                          conditionFact === ConditionFact.SPECIFIC_PRODUCT ? variants?.map(variant => variant.id) : [title],
                          !(selectedProductIdObj[id]?.length >= 0),
                        );
                      }}
                    >
                      <StopPropagation>
                        <Checkbox
                          isChecked={selectedProductIdObj[id]?.length >= 0}
                          onChange={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleProductSelect(
                              id,
                              conditionFact === ConditionFact.SPECIFIC_PRODUCT ? variants?.map(variant => variant.id) : [title],
                              e.target.checked,
                            );
                          }}
                        />
                      </StopPropagation>

                      <Flex alignItems={"center"} flex={1} gap={"12px"}>
                        {conditionFact === "SPECIFIC_PRODUCT" && (
                          <>
                            {images.length > 0 ? (
                              <img src={images[0]} height={"32px"} width={"32px"} alt={title} />
                            ) : (
                              <Icons.ImagePreview w="36px" h="36px" />
                            )}
                          </>
                        )}

                        <Box>
                          <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500}>
                            {title}
                          </Typography>
                          {subTitle && (
                            <Typography mt={0} variant="xsmall">
                              {subTitle}
                            </Typography>
                          )}
                        </Box>
                      </Flex>
                      {conditionFact === "SPECIFIC_PRODUCT" && (
                        <Flex gap={1}>
                          <Typography variant="body" fontSize={"16px"}>
                            {`${currencyFormatter(+price, storeInfo?.currency!)}`}
                          </Typography>
                          <Icons.ChevronRight height={"16px"} width={"16px"} cursor={"pointer"} />
                        </Flex>
                      )}
                    </Flex>
                    <Divider />
                    {/* {variants.length > 1 &&
                  variants.map(variant => (
                    <Flex key={variant.id} my="14px" pl={"56px"} pr="24px" gap="16px">
                      <Checkbox
                        isChecked={productObj[id]?.includes(variant.id)}
                        onChange={e => {
                          handleCheckboxChange(e.target.checked, variant.id, id);
                        }}
                      />
                      <Flex alignItems={"center"} flex={1} gap={"12px"}>
                        <Box>
                          <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500}>
                            {variant.title}
                          </Typography>
                        </Box>
                      </Flex>
                      <Flex gap={1}>
                        <Typography variant="body" fontSize={"16px"}>
                          {variant.price}
                        </Typography>
                      </Flex>
                    </Flex>
                  ))} */}
                  </Box>
                );
              })
            ) : (
              <Flex height={"100%"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                <Typography variant="subheading2" fontWeight={500} lineHeight={"20px"}>
                  {conditionFact === ConditionFact.SPECIFIC_PRODUCT ? t("There are no product in your store") : t("There are no product collection in your store")}
                </Typography>
                <Typography variant="subheading2" fontWeight={500} lineHeight={"20px"}>
                  {conditionFact === ConditionFact.SPECIFIC_PRODUCT ? t("Please add product to your store") : t("Please add product collection to your store")}

                </Typography>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

const Footer: React.FC<FooterProps> = ({ onSave, onCancel, t }) => {
  return (
    <Flex gap="8px" p="16px" justifyContent={"end"}>
      <Button
        padding={"8px 16px"}
        borderRadius={"4px"}
        variant={"outline"}
        border={"1px solid"}
        borderColor={"neutrals.500"}
        onClick={onCancel}
      >
        <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500}>
          {t("Cancel")}
        </Typography>
      </Button>

      <Button
        padding={"8px 16px"}
        borderRadius={"4px"}
        border={"1px solid"}
        borderColor={"neutrals.500"}
        variant={"solid"}
        onClick={onSave}
      >
        {t("Done")}
      </Button>
    </Flex>
  );
};

const StopPropagation = ({ children }: { children: any }) => {
  const stopEventPropagation = (event: any) => {
    event.stopPropagation();
  };

  return (
    <Flex
      onClick={stopEventPropagation}
      onTouchStart={stopEventPropagation}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Flex>
  );
};

export default React.memo(SearchProductModal);
