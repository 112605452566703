import { Box, Flex, Grid } from "@chakra-ui/react";
import { Icons, Typography } from "@reconvert/react-ui-component";

const AnalyticsRow = ({
  name,
  value,
  change,
  isHighlighted,
  isForSidebar,
  variantStatus = true,
  iconSize,
}: {
  name: string;
  value: string;
  change: string;
  isHighlighted?: boolean;
  isForSidebar?: boolean;
  variantStatus?: boolean;
  iconSize?: string;
}) => {

  const formatChange = (change: string) => {
    if (change.length > 7) {
      return `${change.slice(0, 3)}...%`;
    }
    
    return change;
  };

  return (
    <Grid
      templateColumns={isForSidebar ? "122px 83px min-content auto" : "auto min-content 20px 56px"}
      alignItems="center"
      mx={isForSidebar ? "16px" : ""}
      gap={"4px"}
    >
      <Box>
        <Typography
          id="name"
          fontWeight={isHighlighted ? "medium" : "normal"}
          variant="body"
          color={variantStatus ? "neutrals.900" : "disable.color"}
        >
          {name}
        </Typography>{" "}
      </Box>
      {value ? (
        <>
          <Flex justifyContent={isForSidebar ? "start" : "end"}>
            <Typography
              id="value"
              fontWeight={isHighlighted ? "medium" : "normal"}
              variant="body"
              width={"fit-content"}
              color={variantStatus ? "neutrals.900" : "disable.color"}
            >
              {value}
            </Typography>
          </Flex>
          <Flex alignItems={"center"}>
            {variantStatus ? (
              String(change)?.startsWith("-") ? (
                <Icons.AnalyticsArrowsDecrease size={iconSize} />
              ) : (
                <Icons.AnalyticsArrowsIncrease size={iconSize} />
              )
            ) : (
              <Icons.AnalyticsArrowsDisable isDecrease={String(change)?.startsWith("-")} />
            )}
          </Flex>
          <Box
            display={'flex'}
            flexDirection={'row-reverse'}
          >
            <Typography
              id="changes"
              color={variantStatus ? (String(change)?.startsWith("-") ? `error.800` : `success.800`) : "disable.color"}
              variant="body"
              width={"fit-content"}
              whiteSpace={"nowrap"}
            >
              {String(change)?.startsWith("-") ? formatChange(String(change)) : `+${formatChange(String(change))}`}
            </Typography>
          </Box>
        </>
      ) : (
        <Flex justifyContent={"end"}>
          <Typography variant="small">-</Typography>
        </Flex>
      )}
    </Grid>
  );
};

export default AnalyticsRow;
