export const getProductToDisplayVerbose = (value: String) => {
  switch (value) {
    case `SHOPIFY_RECOMMENDATIONS`:
      return `Shopify Recommendations`;

    case `MOST_EXPENSIVE`:
      return `Most expensive product in the cart`;

    case `LEAST_EXPENSIVE`:
      return `Cheapest product in the cart`;

    case `WISER_RECOMMENDATIONS`:
      return `Wiser recommendations`;

    case `PERSONALIZED_RECOMMENDATIONS`:
      return `Personalized recommendations`;

    case `REBUY_INTELLIGENT_CROSS_SELL`:
      return `Rebuy intelligent cross sell`;

    case `RECOMATIC_RECOMMENDATIONS`:
      return `Recomatic recommendations`;

    default:
      break;
  }
};
