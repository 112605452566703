import { Box, Button, Divider, Flex, IconButton, Input, Spinner } from "@chakra-ui/react";
import { AlertDialogModal, Icons, Typography, useToast } from "@reconvert/react-ui-component";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import WidgetAnalytics from "./WidgetAnalytics";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectAppState, setIFrameLink, toggleHelpModal } from "../../../../app/slice/appSlice";
import { APP_PLATFORM, WIDGET_EDITOR_URL } from "../../../../config";
import useConditions from "../../../../hooks/useConditions";
import useVariants from "../../../../hooks/useVariants";
import VariantAnalytics from "../VariantAnalytics";
import useVariantAnalytics from "../../../../hooks/useVariantAnalytics";
import { getToken } from "../../../../services/auth.service";
import { Platform } from "../../../../models";
import { updateVariant } from "../../../../app/slice/conditionSlice";
import useUnsavedBar from "../../../../hooks/useUnsavedBar";

const VariantOverview = () => {
  const { t } = useTranslation();
  const { error } = useToast();
  const { selectedCondition } = useConditions();
  const {
    selectedVariant,
    handleVariantNameUpdate,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingVariant,
    handleDeleteVariant,
  } = useVariants();
  const { activePage } = useAppSelector(selectAppState);
  const { pushUnsavedChanges, preventAction } = useUnsavedBar();
  const dispatch = useAppDispatch();
  const { variantAnalyticData, loading } = useVariantAnalytics();
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(selectedVariant?.name);
  // const [deletingVariant, setDeletingVariant] = useState<boolean>(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const platformStoreId = sessionStorage.getItem("platformStoreId");
  const [preserveName, setPreserveName] = useState(selectedVariant?.name);

  useEffect(() => {
    setName(selectedVariant?.name);
    setPreserveName(selectedVariant?.name);
  }, [selectedVariant]);

  const toggleEdit = () => {
    if (!name) {
      setName(preserveName);
      setIsEdit(!isEdit);
      return error(t("Condition name can't be empty"));
    }

    if (isEdit && selectedCondition) {
      pushUnsavedChanges({
        id: "variantNameChange",
        type: "VARIANT",
        saveAction: () => handleVariantNameUpdate(selectedVariant.id, selectedCondition.name, name),
        discardAction: () => {
          setName(selectedVariant.name);
          dispatch(updateVariant({ id: selectedVariant.id, name: selectedVariant.name }));
        },
      });
    }

    setIsEdit(!isEdit);
  };

  const handleNameChange = (e: any) => {
    if (e.target.value) {
      setPreserveName(e.target.value);
    }

    setName(e.target.value);
  };

  const handleOnClose = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const navigateToEditor = useCallback(async () => {
    if (!selectedVariant) return;

    try {
      const tokenRes = await getToken(platformStoreId!);

      if (tokenRes.response?.token) {
        dispatch(
          setIFrameLink(
            `${WIDGET_EDITOR_URL}?variant=${selectedVariant.id}${activePage! ? `&type=${activePage!}` : ""
            }&platformStoreId=${platformStoreId}&token=${tokenRes.response.token}`,
          ),
        );
      }
    } catch (error) {
    }
  }, [selectedVariant, platformStoreId, dispatch, activePage]);

  const height = `calc(100vh - ${APP_PLATFORM === Platform.Wix ? "60px" : '0px'})`;

  return (
    <Box flexBasis={"350px"} minWidth="350px" bg="white" boxShadow="md" height={height}>
      <>
        <Box p={4} height={height}>
          <Box>
            {isEdit ? (
              <Input
                htmlSize={name?.length}
                width="100%"
                onChange={handleNameChange}
                value={name}
                size={"xs"}
                onBlur={toggleEdit}
              />
            ) : (
              <Typography
                onDoubleClick={toggleEdit}
                textAlign={"left"}
                variant="subheading1"
                fontSize={"16px"}
                color={"neutrals.800"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {name}
              </Typography>
            )}
          </Box>
          <Box my={2}>
            <Typography
              color="link.color"
              variant="body"
              as="a"
              cursor="pointer"
              onClick={() => {
                const link =
                  APP_PLATFORM === Platform.Shopify
                    ? "https://help.reconvert.io/en/article/what-are-slots-and-widgets-1ban4rt/reader/"
                    : "https://help.reconvert.io/en/article/what-are-slots-and-widgets-wix-p5zh8h/reader/?bust=1716462520867";

                dispatch(
                  toggleHelpModal({
                    link: link,
                    title: t("What are slots and widgets?").toString(),
                  }),
                );
              }}
            >
              {t("Learn more about widgets")}
            </Typography>
          </Box>
          <Divider />
          <Flex gap={2} mt={4} justifyContent="space-between">
            <Button
              variant="secondary"
              size={"sm"}
              fontSize={16}
              px={4}
              fontWeight={500}
              flexGrow={1}
              onClick={() => preventAction(() => navigateToEditor())}
            >
              <Typography variant="subheading2" fontWeight="medium" lineHeight={"20px"}>
                {t("Edit widgets")}
              </Typography>
            </Button>
            {/* <Button variant="secondary" size={"sm"} fontSize={16} px={4} fontWeight={500} flexGrow={1}>
                {t("Preview")}
              </Button> */}
          </Flex>

          <Box overflowY="auto" overflowX="hidden" height={"calc(100% - 150px)"} pb={"20px"}>
            {loading ? (
              <>
                <Flex justifyContent={"center"} mt="20px">
                  <Spinner variant={"ring"} size={"md"} />
                </Flex>
              </>
            ) : (
              <VariantAnalytics variantAnalyticData={variantAnalyticData} />
            )}
          </Box>
        </Box>
        <Box sx={{ position: "fixed", bottom: "0px", width: "100%" }} bg="white" boxShadow="md">
          <Divider />
          <Flex pt={4} pl={4} pr={4} pb={"20px"} onClick={() => preventAction(() => setIsDeleteModalOpen(true))} cursor={"pointer"}>
            <IconButton height={"16px"} aria-label={"delete-variant"} variant="unstyled">
              <>
                <Icons.TrashSimple height={"16px"} width={"16px"} color={"#EA3323"} />
                <Typography
                  ml={2}
                  display={"inline"}
                  alignItems={"center"}
                  variant="small"
                  color={"#EA3323"}
                  fontWeight={"medium"}
                >
                  {t("Delete widgets")}
                </Typography>
              </>
            </IconButton>
          </Flex>
        </Box>
        <AlertDialogModal
          title={`${t("Are you sure you want to delete")} ${selectedVariant?.name}?`}
          okButtonText={t("Delete widgets")}
          size={"2xl"}
          isOpen={isDeleteModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleOnClose}
          children={null}
          okButtonOnClick={handleDeleteVariant}
          cancelButtonOnClick={handleOnClose}
          confirmLoading={deletingVariant}
        />
      </>
    </Box>
  );
};

export default React.memo(VariantOverview);
