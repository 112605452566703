import { Box, Flex, InputGroup, InputRightElement, NumberInput, NumberInputField } from "@chakra-ui/react";
import React from "react";
import { Typography } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app/hooks";
import { selectAppState } from "../../../../app/slice/appSlice";

interface CartOrderSelectorProps {
  conditionData: any;
  updateConditionData: (val: { value?: string; fromValue?: string; toValue?: string }) => void;
}

const CartOrderSelector = ({ conditionData, updateConditionData }: CartOrderSelectorProps) => {
  const { t } = useTranslation();
  const { storeInfo } = useAppSelector(selectAppState);

  if (conditionData?.operator === "between" || conditionData?.operator === "notBetween") {
    return (
      <Box mt={2}>
        <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
          {t("Value")}
        </Typography>
        <Flex alignItems={"center"} gap={1}>
          <InputGroup>
            <NumberInput
              value={conditionData?.fromValue}
              onChange={val => {
                updateConditionData({ fromValue: val });
              }}
            >
              <NumberInputField color={"#1A202C"} />
            </NumberInput>
            <InputRightElement>{storeInfo?.currencySymbol}</InputRightElement>
          </InputGroup>
          <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
            and
          </Typography>
          <InputGroup>
            <NumberInput value={conditionData?.toValue} onChange={val => updateConditionData({ toValue: val })}>
              <NumberInputField color={"#1A202C"} />
            </NumberInput>
            <InputRightElement>{storeInfo?.currencySymbol}</InputRightElement>
          </InputGroup>
        </Flex>
      </Box>
    );
  } else {
    return (
      <Box mt={2}>
        <Flex gap={1} flexDirection={"column"}>
          <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
            {t("Value")}
          </Typography>
          <InputGroup>
            <NumberInput
              width={"100%"}
              value={conditionData?.value}
              onChange={val => updateConditionData({ value: val })}
            >
              <NumberInputField color={"#1A202C"} />
            </NumberInput>
            <InputRightElement>{storeInfo?.currencySymbol}</InputRightElement>
          </InputGroup>
        </Flex>
      </Box>
    );
  }
};

export default CartOrderSelector;
