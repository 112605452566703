// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
import { SessionInterceptor } from '@reconvert/react-ui-component';
import { API_ANALYTIC_URL, API_BASE_URL } from "./constant";

const AxiosInstance = axios.create();

SessionInterceptor.addInterceptors(AxiosInstance);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function createRequest<T extends {} = any>(
  url: string,
  options?: RequestInit,
  token?: string,
  isAnalytic?: boolean,
) {
  const getSessionStorageValue = sessionStorage.getItem("platformStoreId") || "";
  const encryptedPlatformStoreId = btoa(getSessionStorageValue);

  let backendBasePrefix = API_BASE_URL;

  if (isAnalytic) {
    backendBasePrefix = API_ANALYTIC_URL;
  }

  AxiosInstance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  try {
    const res = (
      await AxiosInstance.request({
        url: backendBasePrefix + url,
        method: options?.method,
        data: options?.body,
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
          "X-Platform-Store-Id": encryptedPlatformStoreId,
          "X-Reconvert-Auth-Version": 1,
        },
      })
    ).data;

    return { response: res, statusCode: res?.status };
  } catch (error: AxiosError | any) {
    console.error(error);
    return {
      response: { ...(error?.response?.data || {}), success: false, data: null, statusCode: error?.response?.status },
    };
  }
}

export async function getJson<T extends {} = any>(path: string, params?: Record<string, any>, isAnalytic?: boolean) {
  const query = (params && `?${new URLSearchParams(params).toString()}`) ?? "";

  return await createRequest<T>(path + query, { method: "GET" }, undefined, isAnalytic);
}

export async function postJson<T extends {} = any>(path: string, body: Record<string, any> = {}) {
  return await createRequest<T>(path, { method: "POST", body: JSON.stringify(body) });
}

export async function putJson<T extends {} = any>(path: string, body: Record<string, any> = {}) {
  return await createRequest<T>(path, { method: "PUT", body: JSON.stringify(body) });
}

export async function deleteJson<T extends {} = any>(path: string, body: Record<string, any> = {}) {
  return await createRequest<T>(path, { method: "DELETE", body: JSON.stringify(body) });
}

export async function patchJson<T extends {} = any>(path: string, body: Record<string, any> = {}) {
  return await createRequest<T>(path, { method: "PATCH", body: JSON.stringify(body) });
}
