import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addUnsavedChange, clearUnsavedChanges, SaveEntityType, selectUnsavedState, setShowUnSaveModelInfo, UnsavedChange } from "../app/slice/unsavedSlice";
import { selectAppState } from "../app/slice/appSlice";
import { useToast } from "@reconvert/react-ui-component";
import { useTranslation } from "react-i18next";

export const PreventActionTypeObject = {
  CONDITION_CREATE_UPDATE: "CONDITION_CREATE_UPDATE",
  INLINE_CHANGE: "INLINE_CHANGE"
}

export type PreventActionType = "CONDITION_CREATE_UPDATE" | "INLINE_CHANGE";

interface UnsavedBarReturnType {
  handleSave: () => void;
  handleDiscard: () => void;
  preventAction: (callback: any, type?: PreventActionType) => void;
  pushUnsavedChanges: ({
    id,
    type,
    saveAction,
    discardAction,
  }: {
    id: string;
    type: SaveEntityType;
    saveAction: () => void;
    discardAction: () => void;
  }) => Promise<void>;
  unsavedChanges: UnsavedChange[];
}

const OrderOfSaveEntityType: Record<SaveEntityType, number> = {
  WIDGET: 1,
  VARIANT: 2,
  "SLOT-CONFIG": 3,
  SLOT: 4,
  CONDITION: 5,
};

const useUnsavedBar = (): UnsavedBarReturnType => {
  const { t } = useTranslation();
  const { isUnsavedBarEnabled } = useAppSelector(selectAppState);
  const { success } = useToast();
  const { unsavedChanges, isUnsavedBarVisible, isConditionCreateOrUpdate } = useAppSelector(selectUnsavedState);
  const dispatch = useAppDispatch();

  const handleSave = useCallback(async () => {
    const sortedPromise = unsavedChanges.slice().sort((a, b) => a.order - b.order);
    const allUnsavedPromise = sortedPromise.map(a => a.promise());

    const res = await Promise.allSettled(allUnsavedPromise);

    dispatch(clearUnsavedChanges());
    
    if (res.every(a => a.status === "fulfilled")) {
      success(t("Changes saved successfully."))
    }

  }, [dispatch, success, t, unsavedChanges]);

  const handleDiscard = useCallback(async () => {
    const sortedPromise = unsavedChanges.slice().sort((a, b) => a.order - b.order);
    const allUnsavedPromiseForDiscard = sortedPromise.map(a => a.fallback && a.fallback());

    await Promise.allSettled(allUnsavedPromiseForDiscard);

    dispatch(clearUnsavedChanges());
  }, [dispatch, unsavedChanges]);

  const pushUnsavedChanges = useCallback(
    async ({
      id,
      type,
      saveAction,
      discardAction,
    }: {
      id: string;
      type: SaveEntityType;
      saveAction: () => void;
      discardAction: () => void;
    }) => {

      //Only Push to Unsaved Promise when isUnsavedBarEnabled set to TRUE
      if (isUnsavedBarEnabled) {
        dispatch(
          addUnsavedChange({
            id,
            order: OrderOfSaveEntityType[type],
            promise: () =>
              new Promise((resolve, reject) => {
                try {
                  saveAction();
                  resolve(`${id} save successfully!!`);
                } catch (error) {
                  reject(`${id} save rejected!!`);
                }
              }),
            fallback: () =>
              new Promise((resolve, reject) => {
                try {
                  discardAction();
                  resolve(`${id} discard successfully!!`);
                } catch (error) {
                  reject(`${id} discard rejected!!`);
                }
              }),
          }),
        );
      } else {
        saveAction();
      }
    },
    [dispatch, isUnsavedBarEnabled],
  );


  const preventAction = useCallback(
    (callback: any, type?: "CONDITION_CREATE_UPDATE" | "INLINE_CHANGE") => {
      if (isUnsavedBarEnabled && isUnsavedBarVisible) {
        if (!type) {
          dispatch(setShowUnSaveModelInfo({callback}))
          return;
        }

        if (isConditionCreateOrUpdate && type === "INLINE_CHANGE") {
          dispatch(setShowUnSaveModelInfo({callback}))
          return;
        }
      }

      callback();
    },
    [isConditionCreateOrUpdate, isUnsavedBarVisible, isUnsavedBarEnabled, dispatch],
  );

  return {
    unsavedChanges,
    handleSave,
    handleDiscard,
    pushUnsavedChanges,
    preventAction,
  };
};

export default useUnsavedBar;
