import { Box, Flex } from "@chakra-ui/react";
import { APP_PLATFORM } from "../../config";
import { Platform } from "../../models";
import { Content } from "../Content";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import IFrameComponent from "./IFrameComponent";
import { AlertDialogModal, Typography } from "@reconvert/react-ui-component";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { selectUnsavedState, setShowUnSaveModelInfo } from "../../app/slice/unsavedSlice";
import useUnsavedBar from "../../hooks/useUnsavedBar";

const PageLayout = () => {
  const dispatch = useAppDispatch();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { showUnSaveModelInfo } = useAppSelector(selectUnsavedState);
  const { handleDiscard, handleSave } = useUnsavedBar();

  const closeUnSavedModal = useCallback(() => {
    dispatch(setShowUnSaveModelInfo({ callback: () => { } }))
  }, [dispatch])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100vh",
      }}
    >
      {APP_PLATFORM === Platform.Wix && <Header />}
      <Flex height={"max-content"}>
        <Sidebar />
        <Content />
      </Flex>
      <IFrameComponent />
      <AlertDialogModal
        title={t('Would you like to save your changes?')}
        okButtonText={t("Save")}
        cancelButtonText={t('Discard')}
        size={"2xl"}
        isOpen={showUnSaveModelInfo.open}
        leastDestructiveRef={cancelRef}
        onClose={closeUnSavedModal}
        okButtonOnClick={() => {
          handleSave();
          showUnSaveModelInfo.callback();
          closeUnSavedModal();
        }}
        cancelButtonOnClick={()=>{
          handleDiscard();
          showUnSaveModelInfo.callback();
          closeUnSavedModal();
        }}
        showCloseButton={true}
        isOkButtonPrimary={true}
        cancelButtonVariant="secondary"
      >
        <Typography variant="subheading2">
          {t('Warning: Clicking discard at this stage will delete any unsaved changes')}
        </Typography>
      </AlertDialogModal>
    </Box>
  );
};

export default PageLayout;
