import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAppState, setIFrameLink, updateBreadcrumbValue } from "../app/slice/appSlice";
import { createSlotAsync, deleteSlotAsync, setCreatingSlot, updateSlot, updateSlotAsync } from "../app/slice/slotSlice";
import { WIDGET_EDITOR_URL } from "../config";
import { getToken } from "../services/auth.service";
import { EmptySlotOption } from "../models";
import useConditions from "./useConditions";
import { generateBreadcrumbFormat, IFramePostMessageType, sendPostMessage } from "../utils/helpers";
import { setSelectedVariantId } from "../app/slice/conditionSlice";

interface SlotReturnType {
  openDeleteSlotModal: boolean;
  deletingSlot: boolean;
  handleCreateSlot: () => void;
  handleSlotNameUpdate: (id: string, oldName: any, name: any) => void;
  setOpenDeleteSlotModal: (value: boolean) => void;
  handleSlotDelete: (slotNo: number) => void;
  handleSlotEmptyOptionUpdate: (id: string, oldValue: EmptySlotOption, emptySlotOption: EmptySlotOption) => void;
}

const useSlots = (): SlotReturnType => {
  const { t } = useTranslation();
  const { success, error } = useToast();
  const { selectedCondition } = useConditions();
  const { activePage, platformStoreId } = useAppSelector(selectAppState);
  const dispatch = useAppDispatch();
  const [openDeleteSlotModal, setOpenDeleteSlotModal] = useState(false);
  const [deletingSlot, setDeletingSlot] = useState(false);

  const handleCreateSlot = useCallback(() => {
    sendPostMessage(IFramePostMessageType.SLOT_CREATED, { platformStoreId });

    dispatch(setCreatingSlot(true));
    dispatch(createSlotAsync())
      .unwrap()
      .then(async res => {
        success(`${t("Slot created successfully.")}`);

        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({page: res?.data?.location, condition: selectedCondition?.name, variant: res?.data?.variants[0] && res.data.variants[0].name }),
          })
        );

        res?.data?.variants[0] && dispatch(setSelectedVariantId(res.data.variants[0].id));

        const tokenRes = await getToken(platformStoreId!);

        if (tokenRes.response?.token) {
          dispatch(
            setIFrameLink(
              `${WIDGET_EDITOR_URL}?variant=${res.data.variants[0].id}${
                activePage! ? `&type=${activePage!}` : ""
              }&platformStoreId=${platformStoreId}&isNewVariant=true&token=${tokenRes.response.token}`,
            ),
          );
        }
      })
      .catch(() => error(`${t("Error while creating slot.")}`));
  }, [dispatch, platformStoreId, success, t, selectedCondition, activePage, error]);

  const handleSlotNameUpdate = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateSlot({ id, name }));
      dispatch(updateSlotAsync({ id, body: { name } }))
        .unwrap()
        .catch(() => {
          error(t("Failed to change slot name."));
          dispatch(updateSlot({ id, name: oldName }));
        });
    },
    [dispatch, error, t],
  );

  const handleSlotEmptyOptionUpdate = useCallback(
    (id: string, oldValue: EmptySlotOption, emptySlotOption: EmptySlotOption) => {
      dispatch(updateSlot({ id, emptySlotOption }));
      dispatch(updateSlotAsync({ id, body: { emptySlotOption } }))
        .unwrap()
        .catch(() => {
          error(t("Failed to change empty option for slot."));
          dispatch(updateSlot({ id, emptySlotOption: oldValue }));
        });
    },
    [dispatch, error, t],
  );

  const handleSlotDelete = useCallback(
    (slotNo: number) => {
      setDeletingSlot(true);
      dispatch(deleteSlotAsync(slotNo))
        .unwrap()
        .then(res => {
          dispatch(
            updateBreadcrumbValue({
              breadCrumb: generateBreadcrumbFormat({page: res?.data?.location, condition: selectedCondition?.name }),
            }))
          success(t("Slot deleted successfully."));
          setDeletingSlot(false);
          setOpenDeleteSlotModal(false);
        })
        .catch(() => {
          setDeletingSlot(false);
          error(t("Error while deleting slot."));
        });
    },
    [dispatch, t, success, error],
  );

  return {
    deletingSlot,
    openDeleteSlotModal,
    handleCreateSlot,
    handleSlotNameUpdate,
    setOpenDeleteSlotModal,
    handleSlotDelete,
    handleSlotEmptyOptionUpdate,
  };
};

export default useSlots;
