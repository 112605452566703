import { Button, Divider, Flex, Input, Select } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Typography } from "@reconvert/react-ui-component";
import React, { useCallback, useEffect } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Overview, selectConditionsState } from "../../../../app/slice/conditionSlice";
import useEditCondition from "../../../../hooks/useEditCondition";
import ProductSelector from "./ProductSelector";
import CartOrderSelector from "./CartOrderSelector";
import ProductTagSelector from "./ProductTagSelector";
import ProductSkuSelector from "./ProductSkuSelector";
import { APP_PLATFORM } from "../../../../config";
import { Platform } from "../../../../models";
import ProductTextSelector from "./ProductTextSelector";
import { validateRequiredValue } from "../../../../utils/validateConditionData";
import { selectAppState } from "../../../../app/slice/appSlice";
import { getConditionsMetaData } from "../../../../utils/conditionsMetaData";
import { LAUNCH_DARKLY_NEW_CONDITIONS } from "../../../../utils/constant";
import useConditions from "../../../../hooks/useConditions";
import { startConditionCreateOrUpdate, stopConditionCreateOrUpdate } from "../../../../app/slice/unsavedSlice";
import { ConditionFact, PageType } from "@reconvert/reconvert-utils";

const StyledSelect = styled(Select)`
  border: 1px solid #6a7381;
  transition: border-color 0.3s;
  height: 36px;

  &:hover {
    border: 2px solid #6a7381;
  }
`;

const ConditionBox = () => {
  const { t } = useTranslation();
  const { updatingConditions, overview } = useAppSelector(selectConditionsState);

  const { activePage, storeInfo, ldFlags, isUnsavedBarEnabled } = useAppSelector(selectAppState);
  const { selectedCondition, setSelectedConditionId, selectFirstCondition } = useConditions();
  const { conditionData, updateConditionData, handleOnSubmit, canSave, conditionsMetaData } = useEditCondition();
  const dispatch = useAppDispatch();

  const renderConditionValue = useCallback(
    (value: any) => {
      switch (value) {
        case ConditionFact.SPECIFIC_PRODUCT:
          return (
            <ProductSelector
              conditionFact={ConditionFact.SPECIFIC_PRODUCT}
              value={{ productIds: conditionData?.productIds || [], variantIds: conditionData?.variantIds || [] }}
              setValue={(data: { productIds: string[]; variantIds: string[] }) => updateConditionData(data)}
            />
          );

        case ConditionFact.PRODUCT_COLLECTION:
          return (
            <ProductSelector
              conditionFact={ConditionFact.PRODUCT_COLLECTION}
              value={{ productCollectionIds: conditionData?.productCollectionIds, variantIds: [], productIds: [] }}
              setValue={(data: { productCollectionIds?: { id: string; name: string }[] }) =>
                updateConditionData({ productCollectionIds: data?.productCollectionIds })
              }
            />
          );

        case ConditionFact.PRODUCT_TAGS:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.PRODUCT_TAGS}
              label="Tags"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({ items: data.items, itemsInfo: data.info });
              }}
              isOptionCreatable
            />
          );

        case ConditionFact.CUSTOMER_LANGUAGE:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.CUSTOMER_LANGUAGE}
              label="Language"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({ items: data.items, itemsInfo: data.info });
              }}
            />
          );

        case ConditionFact.CUSTOMER_COUNTRY:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.CUSTOMER_COUNTRY}
              label="Customer country"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({ items: data.items, itemsInfo: data.info });
              }}
            />
          );

        case ConditionFact.PRODUCT_SALES_CHANNEL:
          return (
            <ProductTagSelector
              conditionFact={ConditionFact.PRODUCT_SALES_CHANNEL}
              label="Product sales channel"
              itemsValue={conditionData?.items || []}
              setItemsValue={(data: { items: string | string[]; info: string | string[] }) => {
                updateConditionData({ items: data.items, itemsInfo: data.info });
              }}
            />
          );

        case ConditionFact.PRODUCT_TITLE:
          return (
            <ProductTextSelector
              titleText="Title"
              value={conditionData?.value || ""}
              setTitleValue={(data: { value: string }) => updateConditionData(data)}
            />
          );

        case ConditionFact.DISCOUNT_CODE:
          return (
            <ProductTextSelector
              titleText="Discount code"
              value={conditionData?.value || ""}
              setTitleValue={(data: { value: string }) => updateConditionData(data)}
            />
          );

        case ConditionFact.PRODUCT_VARIANT_NAME:
          return (
            <ProductTextSelector
              titleText="Title"
              value={conditionData?.value || ""}
              setTitleValue={(data: { value: string }) => updateConditionData(data)}
            />
          );

        case ConditionFact.PRODUCT_SKU:
          return (
            <ProductSkuSelector
              sku={conditionData?.value || ""}
              setSkuValue={(data: { value: string }) => updateConditionData(data)}
            />
          );

        case ConditionFact.CART_OR_ORDER_VALUE:
          return (
            <CartOrderSelector
              conditionData={conditionData}
              updateConditionData={(data: { value?: string; fromValue?: string; toValue?: string }) =>
                updateConditionData(data)
              }
            />
          );
        default:
          break;
      }
    },
    [conditionData, updateConditionData],
  );

  const getConditionOptions = useCallback(
    (t: TFunction<"translation", undefined>) => {
      const options = [
        { value: conditionsMetaData.SPECIFIC_PRODUCT.value, label: t("Specific product") },
        // { value: conditionsMetaData.PRODUCT_COLLECTION.value, label: t("Product collection") },
        // { value: conditionsMetaData.PRODUCT_VARIANT_NAME.value, label: t("Variant name") },
        // { value: conditionsMetaData.DISCOUNT_CODE.value, label: t("Discount code") },
        // { value: conditionsMetaData.CUSTOMER_LANGUAGE.value, label: t("Language") },
        // { value: conditionsMetaData.CUSTOMER_COUNTRY.value, label: t("Customer country") },
      ];

      if (APP_PLATFORM === Platform.Shopify) {
        options.push({ value: conditionsMetaData.PRODUCT_TITLE.value, label: t("Product title") });
        options.push({ value: conditionsMetaData.PRODUCT_SKU.value, label: t("Product SKU") });
        options.push({ value: conditionsMetaData.CART_OR_ORDER_VALUE.value, label: t("Cart/Order value") });
        options.push({ value: conditionsMetaData.PRODUCT_TAGS.value, label: t("Product tags") });
        options.push({ value: conditionsMetaData.PRODUCT_VARIANT_NAME.value, label: t("Variant name") });
        options.push({ value: conditionsMetaData.DISCOUNT_CODE.value, label: t("Discount code") });
        options.push({ value: conditionsMetaData.CUSTOMER_LANGUAGE.value, label: t("Language") });
        options.push({ value: conditionsMetaData.CUSTOMER_COUNTRY.value, label: t("Customer country") });

        // options.push({ value: conditionsMetaData.PRODUCT_SALES_CHANNEL.value, label: t("Product sales channel") });

        //Previous Offer
        if (activePage !== PageType.CHECKOUT) {
          if (activePage === PageType.POST_PURCHASE_PAGE1) {
            if (storeInfo?.isShopifyPlusMerchant) {
              options.push({ value: conditionsMetaData.PREV_PAGE_OFFER.value, label: t("Previous offer") });
            }
          } else {
            options.push({ value: conditionsMetaData.PREV_PAGE_OFFER.value, label: t("Previous offer") });
          }
        }
      } else {
        options.push({ value: conditionsMetaData.PRODUCT_TITLE.value, label: t("Product title") });
        options.push({ value: conditionsMetaData.PRODUCT_SKU.value, label: t("Product SKU") });
        options.push({ value: conditionsMetaData.CART_OR_ORDER_VALUE.value, label: t("Cart/Order value") });
        if (ldFlags && ldFlags[LAUNCH_DARKLY_NEW_CONDITIONS]) {
          options.push({ value: conditionsMetaData.PRODUCT_VARIANT_NAME.value, label: t("Variant name") });
          options.push({ value: conditionsMetaData.DISCOUNT_CODE.value, label: t("Discount code") });
        }
      }

      return options;
    },
    [activePage, storeInfo?.isShopifyPlusMerchant, ldFlags],
  );

  const updateOperatorValue = (e: any) => {
    const operatorValue = getConditionsMetaData(activePage!, storeInfo?.isShopifyPlusMerchant!, e.target.value);
    const conditionKey = conditionData.fact as ConditionFact;

    updateConditionData({
      condition: e.target.value,
      operator: operatorValue[conditionKey].operatorOptions[0].value,
    });
  };

  useEffect(() => {
    const handleMessage = async (event: any) => {
      if (event?.data?.page !== "flow-builder") return false;

      if (event.data?.isConditionCreateOrUpdate) {
        switch (event.data.event) {
          case "save-form":
            handleOnSubmit();
            break;
          case "discard-form":
            dispatch(stopConditionCreateOrUpdate());
            if (selectedCondition?.id) {
              setSelectedConditionId(selectedCondition?.id);
            } else {
              selectFirstCondition();
            }

            break;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [conditionData, dispatch, handleOnSubmit, selectFirstCondition, selectedCondition?.id, setSelectedConditionId]);

  useEffect(() => {
    if (!isUnsavedBarEnabled) return;

    if (
      overview === Overview.ADD_CONDITION_VIEW ||
      (canSave && overview === Overview.EDIT_CONDITION_VIEW && selectedCondition?.id)
    ) {
      dispatch(startConditionCreateOrUpdate());
    } else {
      dispatch(stopConditionCreateOrUpdate());
    }
  }, [canSave, dispatch, isUnsavedBarEnabled, overview, selectedCondition?.id]);

  return (
    <>
      <Flex py={4} flexDirection={"column"}>
        <Flex gap={"8px"} flexDirection={"column"}>
          {/* {!selectedCondition && ( */}
          <Flex gap={1} flexDirection={"column"}>
            <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
              {t("Condition name")}
            </Typography>
            <Input
              value={conditionData.name}
              onChange={e => updateConditionData({ name: e.target.value })}
              placeholder={t("My new condition")}
              _hover={{
                paddingLeft: "12px",
              }}
            />
          </Flex>
          {/* )} */}

          <Flex gap={1} flexDirection={"column"}>
            <Typography color={"#6A7381"} width={"max-content"} bg={"var(--Natural-100, #F9FAFC)"} variant="small">
              {t("Condition")}
            </Typography>
            <StyledSelect
              value={conditionData.fact}
              onChange={(e: any) => {
                const conditionKey = e.target.value as ConditionFact;

                const operatorValue = getConditionsMetaData(
                  activePage!,
                  storeInfo?.isShopifyPlusMerchant!,
                  conditionsMetaData[e.target.value]?.options[0]?.value,
                );

                updateConditionData({
                  fact: e.target.value,
                  condition: operatorValue[conditionKey]?.options[0]?.value || "",
                  operator: operatorValue[conditionKey]?.operatorOptions[0]?.value || "",
                  value: "",
                  items: [],
                  productIds: [],
                  productCollectionIds: [],
                  variantIds: [],
                });
              }}
            >
              {getConditionOptions(t).map(item => {
                return (
                  <option key={item.value} value={item.value}>
                    {item?.label}
                  </option>
                );
              })}
            </StyledSelect>
          </Flex>
        </Flex>

        {conditionData.fact && (
          <>
            {conditionsMetaData[conditionData?.fact]?.options.length > 0 && (
              <Flex mt="2" gap={1} flexDirection={"column"}>
                <Typography color={"#6A7381"} bg={"var(--Natural-100, #F9FAFC)"} variant="small" width={"max-content"}>
                  {t("Operator 1")}
                </Typography>
                <StyledSelect
                  value={conditionData.condition}
                  onChange={(e: any) => {
                    updateOperatorValue(e);
                  }}
                >
                  {conditionsMetaData[conditionData.fact as keyof typeof conditionsMetaData].options?.map((o: any) => {
                    return <option value={o.value}>{t(o.label)}</option>;
                  })}
                </StyledSelect>
              </Flex>
            )}

            <Flex mt="8px" gap={1} flexDirection={"column"}>
              <Typography color={"#6A7381"} bg={"var(--Natural-100, #F9FAFC)"} variant="small" width={"max-content"}>
                {t("Operator 2")}
              </Typography>
              <StyledSelect
                value={conditionData.operator}
                onChange={(e: any) => updateConditionData({ operator: e.target.value })}
              >
                {conditionsMetaData[conditionData.fact as keyof typeof conditionsMetaData].operatorOptions?.map(
                  (o: any) => {
                    return <option value={o.value}>{t(o.label)}</option>;
                  },
                )}
              </StyledSelect>
            </Flex>

            {renderConditionValue(conditionData.fact as keyof typeof conditionsMetaData)}

            {/* <>
              <Flex mt="8px" gap={1} flexDirection={"column"}>
                <InputGroup
                  onClick={() => {
                    handleSearchProductModal(true);
                  }}
                >
                  <InputLeftElement>
                    <Icons.Search color="#6A7381" />
                  </InputLeftElement>
                  <Input
                    cursor={"pointer"}
                    placeholder={t("Search for products")}
                    _hover={{
                      paddingLeft: "35px",
                      border: "2px solid #6a7381",
                    }}
                  />
                </InputGroup>
              </Flex>

              {isVariantLoading ? (
                <Flex justifyContent={"center"} mt="12px">
                  <Spinner />
                </Flex>
              ) : (
                [...productToShow]?.length > 0 && (
                  <Flex
                    width={"100%"}
                    borderRadius={"4px"}
                    borderLeft={"1px solid var(--Neutral-600, #6A7381)"}
                    borderRight={"1px solid var(--Neutral-600, #6A7381)"}
                    borderBottom={"1px solid var(--Neutral-600, #6A7381)"}
                    flexDirection={"column"}
                  >
                    <Box maxHeight={"300px"} overflowY={"auto"} mr={"4px"}>
                      {[...productToShow]?.slice(0, displayedProducts)?.map((item: any, key: any) => (
                        <Flex
                          px="24px"
                          py="14px"
                          key={"item-" + key}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Flex gap="12px">
                            <img src={item?.images[0]} height={"36px"} width={"36px"} />
                            <Box>
                              <Typography lineHeight={"20px"} variant="subheading2">
                                {item?.title}
                              </Typography>
                              <Typography variant="xsmall">Price ${item?.variants[0]?.price}</Typography>
                            </Box>
                          </Flex>
                          <Icons.CloseIcon
                            height={"16px"}
                            width={"16px"}
                            cursor={"pointer"}
                            onClick={() => handleRemoveProduct(item?.id)}
                          />
                        </Flex>
                      ))}
                    </Box>
                  </Flex>
                )
              )}
            </> */}

            {/* {productToShow?.length > 4 && productToShow?.length > displayedProducts && (
              <Flex mt={"8px"} justifyContent={"end"}>
                <Typography
                  onClick={() => handleLoadMore()}
                  cursor={"pointer"}
                  variant="body"
                  color={"#2F73DA"}
                  lineHeight={"16px"}
                  fontWeight={500}
                >
                  {t("+ 4 more products")}
                </Typography>
              </Flex>
            )} */}

            <Flex my={4} gap="10px">
              <Button
                pt="16px"
                pb="8px"
                py="8px"
                width={"100%"}
                borderRadius={"4px"}
                background={"var(--Buttons-Primary-button, #121417)"}
                onClick={() => handleOnSubmit()}
                colorScheme="primary"
                isLoading={updatingConditions}
                isDisabled={!canSave || validateRequiredValue(conditionData)?.isInValid}
              >
                <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500} color={"#fff"}>
                  {t("Save")}
                </Typography>
              </Button>
              {/* <Button px="16px" py="8px" variant="unstyled" onClick={() => handleOnDelete()}>
                  <Typography variant="subheading2" lineHeight={"20px"} fontWeight={500} color={"#EA3323"}>
                    {t("Delete")}
                  </Typography>
                </Button> */}
            </Flex>
          </>
        )}
      </Flex>
      <Divider />
    </>
  );
};

export default React.memo(ConditionBox);
