import { Box, Flex } from "@chakra-ui/react";
import "./CheckoutPage.css";
import ConditionCreationSidebar from "./components/ConditionCreationSidebar";
import Conditions from "./components/Conditions";
import Slots from "./components/Slots";
import { Platform } from "../../models";
import { APP_PLATFORM } from "../../config";

const CheckoutPage = () => (
  <Flex justifyContent={"space-between"} pl={"24px"} flex={1} h="100%">
    <Flex width={"100%"} justifyContent={"center"}>
      <Flex width={"100%"} maxWidth={"1197px"} justifyContent={"center"}>
        <Conditions></Conditions>
        <Slots></Slots>
      </Flex>
    </Flex>
    <Box flexBasis={"350px"} minWidth="350px" bg="white" boxShadow="md" height={`calc(100vh - ${APP_PLATFORM === Platform.Wix ? '60px' : '0px'})`}>
      <ConditionCreationSidebar></ConditionCreationSidebar>
    </Box>
  </Flex>
);

export default CheckoutPage;
