import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  fetchConditionsAndSlotsAsync,
  getCheckoutProfileIdAsync,
  loginAsync,
  selectAppState,
  setActivePage,
  setIsAppBridgePresent,
  setLDFlags,
  setLoading,
  setPlatformStoreId,
  toggleHelpModal,
  toggleUnsavedBarValue,
  updateBreadcrumbValue,
  updateSideBarSections,
} from "../app/slice/appSlice";
import { PageNames, StoreInfo } from "../app/slice/appSlice.model";
import {
  createConditionAsync,
  selectCondition,
  selectConditionAsync,
  setSelectedVariantId,
} from "../app/slice/conditionSlice";
import { APP_PLATFORM, CRISP_WEBSITE_ID, SHOPIFY_API_KEY } from "../config";
import { Platform } from "../models";
import {
  LAUNCH_DARKLY_CHECKOUT_PAGE_KEY,
  LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY,
  LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY,
  LAUNCH_DARKLY_THANK_YOU_PAGE_KEY,
  SHOPIFY_SAVE_BAR_ON_FLOW_BUILDER,
} from "../utils/constant";
import { isCrispVisible, setupCrispChat, showCrispChat } from "../utils/crisp";
import { useTranslation } from "react-i18next";
import { DashboardVersion2Pages, PageLinkItems } from "../components/Sidebar/Sidebar";
import { LoggerContext } from "@reconvert/react-logger";
import { generateBreadcrumbFormat } from "../utils/helpers";
import { useFlowBuilderSession } from "@reconvert/react-ui-component";
import { PageType } from "@reconvert/reconvert-utils";

// Setting up the Crisp client once storeClient data is loaded
const useCrispSetup = (queryParams: URLSearchParams, storeInfo?: StoreInfo): void => {
  const crispVisible = isCrispVisible();

  useEffect(() => {
    if (storeInfo && storeInfo.storeClientId) {
      setupCrispChat(
        CRISP_WEBSITE_ID,
        APP_PLATFORM === Platform.Wix ? String(storeInfo.storeClientId) : btoa(`${(storeInfo.storeClientId ?? 1) * 6}`),
        storeInfo.email,
        storeInfo.shopOwner,
        { siteName: storeInfo.shopName, url: storeInfo.storeUrl },
        { storeName: storeInfo?.shopName, storeUrl: storeInfo?.storeUrl },
      );
    }

    if (CRISP_WEBSITE_ID && !crispVisible && storeInfo) {
      showCrispChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crispVisible, storeInfo]);
};

// Setting up the LD client once platformStoreId is loaded
const useSetupLD = (platformStoreId: string, dispatch: any): void => {
  const ldClient = useLDClient();
  const ldFlags = useFlags();

  useEffect(() => {
    if (ldClient && platformStoreId) {
      ldClient.identify({ key: platformStoreId! });

      setTimeout(() => {
        dispatch(setLDFlags(ldFlags));
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldClient, platformStoreId, ldFlags]);
};

const useAppBridge = (queryParams: URLSearchParams, dispatch: any): void => {
  // If shopify key is present and isAdmin is not present in query params, that means app bridge is present and app is rendering from Shopify
  if (SHOPIFY_API_KEY && !queryParams.get("isAdmin")) {
    dispatch(setIsAppBridgePresent(true))
  }
}

// Fetching conditions and slots for current active page or refetch it when active page changes
const useLoadConditionsOnActivePageChange = (dispatch: any, activePage?: PageType, selectedConditionId?: string, selectedVariantId?: string): void => {
  const { t } = useTranslation();
  const platformStoreId = sessionStorage.getItem("platformStoreId");
  const helpModalShow = sessionStorage.getItem(`${platformStoreId}-helpModalShow`);

  useEffect(() => {
    if (activePage) {
      dispatch(selectCondition(null));
      dispatch(fetchConditionsAndSlotsAsync())
        .unwrap()
        .then(({ conditions: conditionsRes, slots }: { conditions: any; slots: any }) => {
          if (conditionsRes?.success) {
            if (!conditionsRes.data?.length) {
              const defaultConditionData = {
                name: "Default condition",
                isDefault: 1,
                location: activePage,
              };

              dispatch(createConditionAsync(defaultConditionData))
                .unwrap()
                .then((response: any) => {
                  dispatch(selectCondition(response.data.id));
                  dispatch(selectConditionAsync(response.data.id));
                  if (!helpModalShow && APP_PLATFORM === Platform.Shopify) {
                    sessionStorage.setItem(`${platformStoreId}-helpModalShow`, "true");
                    dispatch(
                      toggleHelpModal({
                        link: "https://help.reconvert.io/en/article/how-to-use-the-flow-builder-120kpr5/reader/?bust=1706874298491",
                        title: t("How to use the Flow Builder").toString(),
                      }),
                    );
                  }
                });
              return;
            }

            const defaultConditionId = selectedConditionId || conditionsRes.data[conditionsRes.data.length - 1].id;

            if (!helpModalShow && slots.length === 0 && APP_PLATFORM === Platform.Shopify) {
              sessionStorage.setItem(`${platformStoreId}-helpModalShow`, "true");
              dispatch(
                toggleHelpModal({
                  link: "https://help.reconvert.io/en/article/how-to-use-the-flow-builder-120kpr5/reader/?bust=1706874298491",
                  title: t("How to use the Flow Builder").toString(),
                }),
              );
            }

            dispatch(selectCondition(defaultConditionId));
            dispatch(selectConditionAsync(defaultConditionId))
              .unwrap()
              .then((res: any) => {
                dispatch(
                  updateBreadcrumbValue({
                    breadCrumb: generateBreadcrumbFormat({page: res?.data?.location, condition: res?.data?.name }),
                  }),
                );
                if (selectedVariantId) {
                  dispatch(setSelectedVariantId(selectedVariantId));
                }
              });
          }
        });
    }
  }, [dispatch, activePage, t, helpModalShow, platformStoreId]);
};

// Removing query parameters from the URL when the app is unloaded
const useRemoveQueryParams = (queryParams: URLSearchParams): void => {
  const { loading } = useAppSelector(selectAppState);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!loading) {
      queryParams.delete("token");
      queryParams.delete("platformStoreId");
      queryParams.delete("redirectUrl");
      queryParams.delete("page");
      queryParams.delete("isFromMigrationWizard");
      queryParams.delete("canAccessCheckout");
      queryParams.delete("openHelpModal");
      queryParams.delete("conditionId");
      queryParams.delete("variantId");
      setSearchParams(queryParams);
    }
  }, [loading]);
}

const useAppInit = (): void => {
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const token = queryParams.get("token") || "";
  const paramPlatformStoreId = queryParams.get("platformStoreId") || sessionStorage.getItem("platformStoreId") || "";

  queryParams.get("platformStoreId") && sessionStorage.setItem("platformStoreId", paramPlatformStoreId);

  const redirectUrl = queryParams.get("redirectUrl");
  const page = queryParams.get("page");
  const isFromMigrationWizard = queryParams.get("isFromMigrationWizard");
  const canAccessCheckout = queryParams.get("canAccessCheckout");
  const openHelpModal = queryParams.get("openHelpModal");
  const isAdmin = queryParams.get("isAdmin");
  const selectedConditionId = queryParams.get("conditionId") || '';
  const selectedVariantId = queryParams.get("variantId") || '';
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { logger } = useContext(LoggerContext);
  const { setPageInSession } = useFlowBuilderSession();

  const { platformStoreId, storeInfo, ldFlags, activePage, sideBarSections } = useAppSelector(selectAppState);

  useCrispSetup(queryParams, storeInfo);
  useSetupLD(platformStoreId, dispatch);
  useAppBridge(queryParams, dispatch);

  const handleHelpVideoShow = () => {
    const link =
      APP_PLATFORM === Platform.Shopify
        ? "https://help.reconvert.io/en/article/how-to-use-the-flow-builder-120kpr5/reader/?bust=1706874298491"
        : "https://help.reconvert.io/en/article/reconvert-flow-builder-for-wix-1q3qudk/reader/?bust=1716296560710";

    dispatch(
      toggleHelpModal({
        link: link,
        title: t("How to use the Flow Builder").toString(),
      }),
    );
  };

  const handleSave = () => {
    console.log("debug handle save : ");
  };

  const eventsName = {
    HelpVideoShow: "help-video-show",
    HandleSave: "handle-save",
  };

  useEffect(() => {
    const handleMessage = async (event: any) => {
      if (event?.data?.page !== "flow-builder") return false;

      switch (event.data.event) {
        case eventsName.HelpVideoShow:
          handleHelpVideoShow();
          break;
        case eventsName.HandleSave:
          handleSave();
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionStorage.setItem("platformStoreId", paramPlatformStoreId);
    dispatch(setPlatformStoreId(paramPlatformStoreId));
  }, [dispatch, paramPlatformStoreId, token]);

  useEffect(() => {
    if (redirectUrl) {
      sessionStorage.setItem("redirectUrl", redirectUrl || "");
    }
  }, [redirectUrl]);

  useEffect(() => {
    dispatch(loginAsync(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (storeInfo) {
      dispatch(getCheckoutProfileIdAsync());
    }
  }, [dispatch, storeInfo]);

  const getDefaultPage = useCallback(() => {
    let firstPage;

    if (storeInfo && ldFlags && !activePage) {
      if (page) {
        firstPage = page as PageType;
        const item = PageLinkItems.find(a => a.type.toString() === page);

        if (item) {
          const isActiveDashboardV2Page =
            DashboardVersion2Pages.includes(item.type) && storeInfo?.dashboardVersion === 2;

          if (isActiveDashboardV2Page && ldFlags[item?.launchDarklyFlag!]) {
            return firstPage;
          } else if (ldFlags[item?.launchDarklyFlag!]) {
            return firstPage;
          }
        }
      }

      if (APP_PLATFORM! === Platform.Wix) {
        firstPage = PageType.CHECKOUT;
      } else {
        if (ldFlags[LAUNCH_DARKLY_CHECKOUT_PAGE_KEY]) {
          firstPage = PageType.CHECKOUT;
        } else if (ldFlags[LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY]) {
          firstPage = PageType.POST_PURCHASE_PAGE1;
        } else if (ldFlags[LAUNCH_DARKLY_THANK_YOU_PAGE_KEY]) {
          firstPage = PageType.THANK_YOU_PAGE;
        } else if (ldFlags[LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY]) {
          firstPage = PageType.ORDER_STATUS_PAGE;
        }
      }

      return firstPage;
    }
  }, [activePage, page, ldFlags, storeInfo]);

  useEffect(() => {
    if (storeInfo && ldFlags && !activePage) {
      //Sidebar Section
      let filterSideBarSections = sideBarSections
        .filter(a => a.enabled)
        .map(c => {
          return {
            ...c,
            links: c.links.filter(l => {
              if (!l.allowedPlatform.includes(APP_PLATFORM!)) return false;

              if (APP_PLATFORM === Platform.Wix) return true;

              if (l.launchDarklyFlag) {
                const hasAccess = ldFlags && ldFlags[l.launchDarklyFlag];

                if (hasAccess && DashboardVersion2Pages.includes(l.type)) {
                  if (isFromMigrationWizard === "true") {
                    return true;
                  }

                  return storeInfo?.dashboardVersion === 2;
                }

                if (hasAccess && l.type === PageType.CHECKOUT) {
                  return storeInfo?.checkoutVersion === 2;
                }

                return hasAccess;
              }

              return true;
            }),
          };
        });

      if (canAccessCheckout) {
        filterSideBarSections = filterSideBarSections.map(d => {
          return {
            ...d,
            links: d.links.map(l => {
              return {
                ...l,
                disabled: l.type === PageType.CHECKOUT ? canAccessCheckout !== "true" : false,
                toolTip:
                  l.type === PageType.CHECKOUT && canAccessCheckout !== "true"
                    ? t("Limited to Stores on the Shopify Plus Plan")
                    : undefined,
              };
            }),
          };
        });
      }

      if (openHelpModal) {
        dispatch(
          toggleHelpModal({
            link: "https://help.reconvert.io/en/article/how-to-use-the-flow-builder-120kpr5/reader/?bust=1706874298491",
            title: t("How to use the Flow Builder").toString(),
          }),
        );
      }

      logger.log(`FB Sidebar Page Value Debug :: ${platformStoreId}`, {
        filterSideBarSections: filterSideBarSections,
        canAccessCheckout: canAccessCheckout,
        isFromMigrationWizard: isFromMigrationWizard,
        ldFlags: ldFlags,
      });

      dispatch(updateSideBarSections(filterSideBarSections));
      let firstPage = getDefaultPage();

      if (firstPage && !filterSideBarSections.some(a => a.links.some(l => l.type === firstPage))) {
        firstPage =
          filterSideBarSections[0].links.length > 0 ? filterSideBarSections[0].links[0].type : PageType.THANK_YOU_PAGE;
      }

      setPageInSession(firstPage);
      dispatch(setActivePage(firstPage));

      // Hide loader once storeInfo & ldFlags are loaded and activePage is set
      dispatch(setLoading(false));
    }
  }, [
    storeInfo,
    activePage,
    ldFlags,
    dispatch,
    page,
    sideBarSections,
    isFromMigrationWizard,
    getDefaultPage,
    canAccessCheckout,
    t,
    logger,
    platformStoreId,
  ]);

  useEffect(()=>{
    if(APP_PLATFORM === Platform.Shopify && !isAdmin && ldFlags && ldFlags[SHOPIFY_SAVE_BAR_ON_FLOW_BUILDER]) {
      dispatch(toggleUnsavedBarValue(true))
    }
  },[dispatch, isAdmin, ldFlags]);

  useLoadConditionsOnActivePageChange(dispatch, activePage, selectedConditionId, selectedVariantId);
  useRemoveQueryParams(queryParams);
};

export default useAppInit;
