import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAppState, setIFrameLink, updateBreadcrumbValue } from "../app/slice/appSlice";
import {
  createVariantAsync,
  deleteVariantAsync,
  selectConditionsState,
  setSelectedVariantId,
  updateVariant,
  updateVariantAsync,
} from "../app/slice/conditionSlice";
import { WIDGET_EDITOR_URL } from "../config";
import { Slot } from "../models";
import useConditions from "./useConditions";
import { getToken } from "../services/auth.service";
import { generateBreadcrumbFormat } from "../utils/helpers";
import useUnsavedBar from "./useUnsavedBar";

interface VariantReturnType {
  selectedVariant: any;
  handleSelectedVariantUpdate: (id: string) => void;
  handleVariantStatusUpdate: (id: string, oldStatus: any, status: any) => void;
  handleVariantNameUpdate: (id: string, oldName: any, name: any) => void;
  handleAddWidget: (slot: Slot, variantId?: string, variantName?: string) => void;
  creatingVariant: boolean;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (value: boolean) => void;
  deletingVariant: boolean;
  handleDeleteVariant: () => void;
}

const useVariants = (): VariantReturnType => {
  const { t } = useTranslation();
  const { success, error } = useToast();
  const dispatch = useAppDispatch();
  const { pushUnsavedChanges } = useUnsavedBar();
  const { platformStoreId, activePage, isUnsavedBarEnabled } = useAppSelector(selectAppState);
  const { selectedVariantId } = useAppSelector(selectConditionsState);
  const { selectedCondition } = useConditions();
  const selectedVariant = useMemo(
    () => (selectedCondition?.variants || []).find((v: any) => v.id === selectedVariantId),
    [selectedCondition, selectedVariantId],
  );
  const [creatingVariant, setCreatingVariant] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingVariant, setDeletingVariant] = useState<boolean>(false);

  const handleSelectedVariantUpdate = useCallback(
    (id: string) => {
      dispatch(setSelectedVariantId(id));
    },
    [dispatch],
  );

  useEffect(() => {
    selectedVariant?.name &&
      dispatch(
        updateBreadcrumbValue({
          breadCrumb: generateBreadcrumbFormat({ page: activePage, condition: selectedCondition?.name, variant: selectedVariant?.name }),
        }),
      );
  }, [activePage, dispatch, selectedCondition?.name, selectedVariant?.name]);

  const handleVariantStatusUpdate = useCallback(
    (id: string, oldStatus: any, status: any) => {
      dispatch(updateVariant({ id, status }));

      pushUnsavedChanges({
        id: "variantStatusChange",
        type: "VARIANT",
        saveAction: () => dispatch(updateVariantAsync({ id, body: { status } }))
          .unwrap()
          .then(() => {
            if (!isUnsavedBarEnabled) success(t("Variant status changed successfully."))
          })
          .catch(() => {
            error(t("Failed to change variant status."));
            dispatch(updateVariant({ id, status: oldStatus }));
          }),
        discardAction: () => dispatch(updateVariant({ id, status: oldStatus }))
      });
    },
    [dispatch, pushUnsavedChanges, isUnsavedBarEnabled, success, t, error],
  );

  const handleVariantNameUpdate = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateVariant({ id, name }));
      dispatch(updateVariantAsync({ id, body: { name } }))
        .unwrap()
        .then(() => {
          if (!isUnsavedBarEnabled) success(t("Variant name changed successfully."))
        })
        .catch(() => {
          error(t("Failed to change variant name."));
          dispatch(updateVariant({ id, name: oldName }));
        });
    },
    [dispatch, isUnsavedBarEnabled, success, t, error],
  );

  const handleAddWidget = useCallback(
    async (slot: Slot, variantId?: string, variantName?: string) => {
      if (variantId) {
        dispatch(setSelectedVariantId(variantId));
        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({page: activePage, condition: selectedCondition?.name, variant: variantName }),
          }),
        );

        const tokenRes = await getToken(platformStoreId!);

        if (tokenRes.response?.token) {
          dispatch(
            setIFrameLink(
              `${WIDGET_EDITOR_URL}?variant=${variantId}${activePage! ? `&type=${activePage!}` : ""
              }&platformStoreId=${platformStoreId}&token=${tokenRes.response.token}`,
            ),
          );
        }
      } else {
        setCreatingVariant(true);
        const body = {
          name: `${selectedCondition?.isDefault ? "Default" : selectedCondition?.name} - ${slot.name.toLocaleLowerCase()}`,
          coverage: 100,
          slotId: slot.id,
          conditionId: selectedCondition?.id,
        };

        dispatch(createVariantAsync(body))
          .unwrap()
          .then(async (res: any) => {
            dispatch(setSelectedVariantId(res?.data?.id));
            dispatch(
              updateBreadcrumbValue({
                breadCrumb: generateBreadcrumbFormat({page: activePage, condition: selectedCondition?.name, variant: res?.data?.name }),
              }),
            );
            const tokenRes = await getToken(platformStoreId!);

            if (tokenRes.response?.token) {
              dispatch(
                setIFrameLink(
                  `${WIDGET_EDITOR_URL}?variant=${res.data.id}${activePage! ? `&type=${activePage!}` : ""
                  }&platformStoreId=${platformStoreId}&isNewVariant=true&token=${tokenRes.response.token}`,
                ),
              );
            }
          })
          .catch(() => error(t("Failed to add variant.")))
          .finally(() => setCreatingVariant(false));
      }
    },
    [dispatch, activePage, platformStoreId, selectedCondition, error, t],
  );

  const handleDeleteVariant = useCallback(() => {
    if (!selectedVariantId) return;

    setDeletingVariant(true);
    dispatch(deleteVariantAsync(selectedVariantId))
      .unwrap()
      .then(() => {
        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({ page: activePage, condition: selectedCondition?.name }),
          }),
        );
        success(t("Variant deleted successfully."))
      })
      .catch(() => error(t("Error while deleting variant.")))
      .finally(() => {
        setIsDeleteModalOpen(false);
        setDeletingVariant(false);
      });
  }, [dispatch, success, error, t, selectedVariantId]);

  return {
    selectedVariant,
    handleSelectedVariantUpdate,
    handleVariantStatusUpdate,
    handleVariantNameUpdate,
    handleAddWidget,
    creatingVariant,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingVariant,
    handleDeleteVariant,
  };
};

export default useVariants;
