import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IFramePostMessageType, sendPostMessage } from "../../utils/helpers";

export type SaveEntityType = "CONDITION" | "SLOT" | "WIDGET" | "SLOT-CONFIG" | "VARIANT";

export interface UnsavedChange {
  id: string;
  order: number;
  promise: () => Promise<any>;
  fallback?: () => Promise<any>;
}

interface UnsavedState {
  isUnsavedBarVisible: boolean;
  isConditionCreateOrUpdate: boolean;
  isInlineUpdate: boolean;
  unsavedChanges: UnsavedChange[];
  showUnSaveModelInfo: {
    open: boolean;
    callback: () => void
  };
}

const initialState: UnsavedState = {
  isUnsavedBarVisible: false,
  isInlineUpdate: false,
  isConditionCreateOrUpdate: false,
  unsavedChanges: [],
  showUnSaveModelInfo: {
    open: false,
    callback: () => {}
  },
};

const unsavedSlice = createSlice({
  name: "unsaved",
  initialState,
  reducers: {
    addUnsavedChange: (state, action: PayloadAction<UnsavedChange>) => {
      if (!state.isUnsavedBarVisible) {
        sendPostMessage(IFramePostMessageType.SHOW_SAVE_BAR, {
        isInlineChange: true,
      });
      }

      state.isInlineUpdate = true;
      state.isConditionCreateOrUpdate = false;
      state.isUnsavedBarVisible = true;
      state.unsavedChanges.push(action.payload);
    },
    clearUnsavedChanges: state => {
      if (state.isUnsavedBarVisible) {
        sendPostMessage(IFramePostMessageType.HIDE_SAVE_BAR);
      }

      state.isInlineUpdate = false;
      state.isConditionCreateOrUpdate = false;
      state.isUnsavedBarVisible = false;
      state.unsavedChanges = [];
    },
    startConditionCreateOrUpdate: state => {
      sendPostMessage(IFramePostMessageType.SHOW_SAVE_BAR, {
        isConditionCreateOrUpdate: true,
      });

      state.unsavedChanges = [];
      state.isConditionCreateOrUpdate = true;
      state.isUnsavedBarVisible = true;
    },
    stopConditionCreateOrUpdate: state => {
      if(state.isUnsavedBarVisible){
        sendPostMessage(IFramePostMessageType.HIDE_SAVE_BAR);
      }

      state.unsavedChanges = [];
      state.isUnsavedBarVisible = false;
      state.isConditionCreateOrUpdate = false;
    },
    setShowUnSaveModelInfo: (state, action: PayloadAction<{callback : () => void}>) => {
      state.showUnSaveModelInfo = {
        open : !state.showUnSaveModelInfo.open,
        callback: action.payload.callback
      };
    }
  },
  extraReducers: builder => {
    return builder;
  },
});

export const selectUnsavedState = (state: RootState) => state.unsaved;

export const {
  addUnsavedChange,
  clearUnsavedChanges,
  startConditionCreateOrUpdate,
  stopConditionCreateOrUpdate,
  setShowUnSaveModelInfo
} = unsavedSlice.actions;
export default unsavedSlice.reducer;
