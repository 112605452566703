import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slice/appSlice";
import conditionReducer from "./slice/conditionSlice";
import slotReducer from "./slice/slotSlice";
import analyticsReducer from "./slice/analyticsSlice";
import unsavedReducer from "./slice/unsavedSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    conditions: conditionReducer,
    slots: slotReducer,
    analytics: analyticsReducer,
    unsaved: unsavedReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
