import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAppState, setIFrameLink, updateBreadcrumbValue } from "../app/slice/appSlice";
import {
  createConditionAsync,
  deleteConditionAsync,
  selectCondition,
  selectConditionAsync,
  selectConditionsState,
  setUpdatingConditions,
  updateCondition,
  updateConditionAsync,
  updateConditionsAsync,
  updateConditionsBasedOnPriority,
  updateSlotConfig,
  updateSlotConfigurationAsync,
} from "../app/slice/conditionSlice";
import { Condition, EmptySlotOption } from "../models";
import { generateBreadcrumbFormat } from "../utils/helpers";
import { stopConditionCreateOrUpdate } from "../app/slice/unsavedSlice";
import useUnsavedBar from "./useUnsavedBar";

interface ConditionsReturnType {
  selectedCondition: Condition | undefined;
  updateConditionStatus: (id: string, oldStatus: any, status: any) => void;
  updateConditionPriority: (
    updateConditionPayload: { id: string; priority: number }[],
    updatedCondition: Condition[],
    conditionArray: Condition[],
  ) => void;
  handleUpdateConditionName: (id: string, oldName: any, name: any) => void;
  handleUpdateCondition: (id: string, body: any) => void;
  handleCreateCondition: (body: any) => void;
  setSelectedConditionId: (id: string | null) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  deletingCondition: boolean;
  handleDeleteCondition: () => void;
  selectFirstCondition: () => void;
  clostIFrame: () => void;
  handleUpdateSlotConfiguration: (id: string, oldValue: EmptySlotOption, emptySlotOption: EmptySlotOption) => void;
}

const useConditions = (): ConditionsReturnType => {
  const { t } = useTranslation();
  const { isUnsavedBarEnabled } = useAppSelector(selectAppState);
  const { success, error } = useToast();
  const dispatch = useAppDispatch();
  const { conditions, selectedConditionId } = useAppSelector(selectConditionsState);
  const { pushUnsavedChanges } = useUnsavedBar();
  const [deletingCondition, setDeletingCondition] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const selectedCondition = useMemo(
    () => (conditions || []).find((c: any) => c.id === selectedConditionId),
    [conditions, selectedConditionId],
  );

  const updateConditionStatus = useCallback(
    (id: string, oldStatus: any, status: any) => {
      dispatch(updateCondition({ id, status }));
      pushUnsavedChanges({
        id: "conditionStatusChange",
        type: "CONDITION",
        saveAction: () => dispatch(updateConditionAsync({ id, body: { status } }))
          .unwrap()
          .then(() => {
            if(!isUnsavedBarEnabled){
              success(t("Condition status changed successfully."))
            }
          })
          .catch(() => {
            error(t("Failed to change condition status."));
            dispatch(updateCondition({ id, status: oldStatus }));
          }),
        discardAction: () => dispatch(updateCondition({ id, status: oldStatus }))
      })
    },
    [dispatch, pushUnsavedChanges, isUnsavedBarEnabled, success, t, error],
  );

  const updateConditionPriority = useCallback(
    (
      updateConditionPayload: { id: string; priority: number }[],
      updatedCondition: Condition[],
      conditionArray: Condition[],
    ) => {
      dispatch(updateConditionsBasedOnPriority({ conditions: updatedCondition }));
      pushUnsavedChanges({
        id: "updateConditionPriority",
        type: "CONDITION",
        saveAction: () => dispatch(updateConditionsAsync(updateConditionPayload))
          .unwrap()
          .then(() => { })
          .catch(e => {
            console.log("Failed to change condition Priority", e);
            error(t("Failed to change condition Priority."));
            dispatch(updateConditionsBasedOnPriority({ conditions: conditionArray }));
          }),
        discardAction: () => dispatch(updateConditionsBasedOnPriority({ conditions: conditionArray }))
      })

    },
    [dispatch, error, pushUnsavedChanges, t],
  );

  const handleUpdateConditionName = useCallback(
    (id: string, oldName: any, name: any) => {
      dispatch(updateCondition({ id, name }));
      dispatch(updateConditionAsync({ id, body: { name } }))
        .unwrap()
        .then(response => {
          dispatch(
            updateBreadcrumbValue({
              breadCrumb: generateBreadcrumbFormat({ page: response?.data?.location, condition: response?.data?.name }),
            }),
          );
          success(t("Condition name changed successfully."));
        })
        .catch(() => {
          error(t("Failed to change condition name."));
          dispatch(updateCondition({ id, name: oldName }));
        });
    },
    [dispatch, success, error, t],
  );

  const handleUpdateCondition = useCallback(
    (id: string, body: any) => {
      dispatch(setUpdatingConditions(true));
      dispatch(updateConditionAsync({ id, body }))
        .unwrap()
        .then(response => {
          dispatch(
            updateBreadcrumbValue({
              breadCrumb: generateBreadcrumbFormat({ page: response?.data?.location, condition: response?.data?.name }),
            }),
          );
          success(t("Condition updated successfully"));
          dispatch(stopConditionCreateOrUpdate());
        })
        .catch(() => error(t("Error while saving condition")))
        .finally(() => dispatch(setUpdatingConditions(false)));
    },
    [dispatch, success, error, t],
  );

  const handleCreateCondition = useCallback(
    (body: any) => {
      dispatch(setUpdatingConditions(true));
      dispatch(createConditionAsync(body))
        .unwrap()
        .then(response => {
          dispatch(
            updateBreadcrumbValue({
              breadCrumb: generateBreadcrumbFormat({ page: response?.data?.location, condition: response?.data?.name }),
            }),
          );
          success(t("Condition created successfully"));
          dispatch(stopConditionCreateOrUpdate());
          dispatch(selectCondition(response.data.id));
          dispatch(selectConditionAsync(response.data.id));
        })
        .catch(() => error(t("Error while creating condition")))
        .finally(() => dispatch(setUpdatingConditions(false)));
    },
    [dispatch, success, error, t],
  );

  const setSelectedConditionId = useCallback(
    (id: string | null) => {
      dispatch(selectCondition(id));
      
      if (id) {
        dispatch(selectConditionAsync(id))
          .unwrap()
          .then(res =>
            dispatch(
              updateBreadcrumbValue({
                breadCrumb: generateBreadcrumbFormat({ page: res?.data?.location, condition: res?.data?.name }),
              }),
            ),
          )
          .catch(() => error(t("Failed to fetch condition details.")));
      }
    },
    [dispatch, error, t],
  );

  const handleDeleteCondition = useCallback(() => {
    if (!selectedConditionId) return;

    const index = (conditions || []).findIndex((c: any) => c.id === selectedConditionId);
    const nextSelectedCondition = conditions[index + 1];

    setDeletingCondition(true);
    dispatch(deleteConditionAsync(selectedConditionId))
      .unwrap()
      .then(() => {
        dispatch(
          updateBreadcrumbValue({
            breadCrumb: generateBreadcrumbFormat({
              page: nextSelectedCondition?.location,
              condition: nextSelectedCondition?.name,
            }),
          }),
        );
        success(t("Condition deleted successfully."));
        dispatch(selectCondition(nextSelectedCondition?.id));
        dispatch(selectConditionAsync(nextSelectedCondition?.id));
      })
      .catch(() => error(t("Error while deleting Condition.")))
      .finally(() => {
        setDeletingCondition(false);
        setIsDeleteModalOpen(false);
      });
  }, [dispatch, selectedConditionId, success, error, t, conditions]);

  const selectFirstCondition = useCallback(() => {
    const nextSelectedConditionId = conditions[0]?.id;

    dispatch(selectCondition(nextSelectedConditionId));

    dispatch(selectConditionAsync(nextSelectedConditionId));
  }, [dispatch, conditions]);

  const clostIFrame = useCallback(() => {
    dispatch(setIFrameLink(undefined));
    setSelectedConditionId(selectedConditionId);
  }, [dispatch, selectedConditionId, setSelectedConditionId]);

  const handleUpdateSlotConfiguration = useCallback(
    (id: string, oldValue: EmptySlotOption, emptySlotOption: EmptySlotOption) => {
      dispatch(updateSlotConfig({ id, emptySlotOption })); // id => currentSlotConfiguration.id

      pushUnsavedChanges({
        id: "slotEmptyOptionsChange",
        type: "SLOT-CONFIG",
        saveAction: () => {
          dispatch(updateSlotConfigurationAsync({ id, body: { emptySlotOption } }))
            .unwrap()
            .catch(() => {
              error(t("Failed to change empty option for slotConfig."));
              dispatch(updateSlotConfig({ id, emptySlotOption: oldValue }));
            });
        },
        discardAction: () => dispatch(updateSlotConfig({ id, emptySlotOption: oldValue }))
      })
    },
    [dispatch, error, pushUnsavedChanges, t],
  );

  return {
    selectedCondition,
    updateConditionStatus,
    handleUpdateConditionName,
    handleUpdateCondition,
    handleCreateCondition,
    setSelectedConditionId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletingCondition,
    handleDeleteCondition,
    selectFirstCondition,
    clostIFrame,
    updateConditionPriority,
    handleUpdateSlotConfiguration,
  };
};

export default useConditions;
