import { useEffect, useState } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { DropDown } from "@reconvert/react-ui-component";
import { getCountries, getLanguage, getProductSalesChannels, getTags } from "../../../../services/conditions.service";
import { ConditionFact } from "@reconvert/reconvert-utils";

interface ProductTagSelectorProps {
  conditionFact: ConditionFact;
  label: string;
  itemsValue: string | string[];
  setItemsValue: (data: { items: string | string[]; info: string | string[] }) => void;
  isOptionCreatable?: boolean;
}

const ProductTagSelector = ({
  itemsValue,
  setItemsValue,
  label,
  conditionFact,
  isOptionCreatable = false,
}: ProductTagSelectorProps) => {
  const [key, setKey] = useState<string>(conditionFact);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setIsLoading(true);
        switch (conditionFact) {
          case ConditionFact.PRODUCT_TAGS:
            try {
              let newTags: string[] = [];
              const items = await getTags();

              if (Array.isArray(itemsValue) && itemsValue.length > 0) {
                newTags = itemsValue?.filter((element: string) => !items?.tags?.includes(element));
              }

              const tagsWithLabel = items?.tags?.map((tag: string) => {
                return { label: tag, value: tag };
              });

              const newTagsWithLabel =
                newTags?.map((tag: string) => {
                  return { label: tag, value: tag };
                }) || [];

              setOptions([...tagsWithLabel, ...newTagsWithLabel]);
            } catch (error) {
              console.error("Failed to fetch tags", error);
            }
            break;

          case ConditionFact.PRODUCT_SALES_CHANNEL:
            try {
              const salesChannels = await getProductSalesChannels({
                limit: 100,
                startCursor: "",
                endCursor: "",
              });

              setOptions(
                salesChannels?.channels?.map((a: any) => {
                  return { label: a.name, value: a.id.split("/").pop() };
                }),
              );
            } catch (error) {
              console.error("Failed to fetch product sales channels:", error);
            }
            break;

          case ConditionFact.CUSTOMER_LANGUAGE:
            try {
              const languageResponse = await getLanguage();

              setOptions(
                languageResponse?.languages?.map((a: any) => {
                  return { label: a.name, value: a.code };
                }),
              );
            } catch (error) {
              console.error("Failed to fetch languages:", error);
            }
            break;

          case ConditionFact.CUSTOMER_COUNTRY:
            try {
              const countriesResponse = await getCountries();

              setOptions(
                countriesResponse?.countries?.map((a: any) => {
                  return { label: a.name, value: a.code };
                }),
              );
            } catch (error) {
              console.error("Failed to fetch countries:", error);
            }
            break;

          default:
            console.error("Unknown conditionFact:", conditionFact);

            break;
        }
      } catch (error) {
        console.error("Failed to fetch options:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
    setKey(conditionFact);
  }, [conditionFact]);

  return (
    <Box mt={2}>
      {!isLoading ? (
        <DropDown
          key={key}
          isMulti
          label={label}
          name={label}
          labelWithBackground
          onValueChange={val => {
            setItemsValue({ items: val, info: options.filter(a => val.includes(a.value)).map(a => a.label) });
          }}
          options={options || []}
          showLabel
          showCheckBox
          size="sm"
          tagView
          value={itemsValue}
          autowrap
          isCreatable={isOptionCreatable}
          onCreateOption={(val: string) => {
            setOptions([...options, { label: val, value: val }]);
            setItemsValue({
              items: [...itemsValue, val],
              info: [...options.filter(a => itemsValue.includes(a.value)).map(a => a.label), val],
            });
            setKey(new Date().toString());
          }}
        />
      ) : (
        <Flex justifyContent={"center"} mt="12px">
          <Spinner />
        </Flex>
      )}
    </Box>
  );
};

export default ProductTagSelector;
