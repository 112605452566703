import dayjs from "dayjs";
import { AnalyticsRecord, AnalyticsDiff } from "../app/slice/analyticsSlice";
import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { PageNames } from "../app/slice/appSlice.model";
import { PageType } from "@reconvert/reconvert-utils";

export enum IFramePostMessageType {
  UPDATE_BREADCRUMB = "update-breadcrumb",
  SLOT_CREATED = "slot-created",
  SHOW_SAVE_BAR = "show-save-bar",
  HIDE_SAVE_BAR = "hide-save-bar",
  UPDATE_SELECTED_PAGE = "update-selected-page",
  UPDATE_SELECTED_CONDITION = "update-selected-condition",
  UPDATE_SELECTED_VARIANT = "update-selected-variant",
}

export function areEqualArray(arr1: number[] | string[], arr2: number[] | string[]) {
  if (arr1.length !== arr2.length) return false;

  let c1 = arr1.slice().sort();
  let c2 = arr2.slice().sort();

  for (let i = 0; i < c1.length; i++) {
    if (c1[i] !== c2[i]) return false;
  }

  return true;
}

export function dateDifferenceInDays(date1: Date, date2: Date) {
  const startDate = dayjs(date1);
  const endDate = dayjs(date2);

  return endDate.diff(startDate, "day");
}

export function dateFormatYYYYMMDD(date: Date) {
  return dayjs(date).format("YYYY-MM-DD");
}

export function subtractDaysAndFormat(daysToSubtract: number, format = "YYYY-MM-DD", dateString?: string | null) {
  const date = dateString ? dayjs(dateString) : dayjs();

  const subtractedDate = date.subtract(daysToSubtract, "day");

  const formattedDate = subtractedDate.format(format);

  return formattedDate;
}

export function calculatePercentageDifference(currentValue: number | string, previousValue: number | string): string {
  const current = +currentValue;
  const previous = +previousValue;

  if (previous === 0) {
    return current === 0 ? "0%" : "100%";
  }

  const percentageDifference = ((current - previous) / previous) * 100;
  const roundedPercentage = percentageDifference.toFixed(0);

  return roundedPercentage + "%";
}

export function calculateDifferences(currentItem: AnalyticsRecord, previousItem: AnalyticsRecord): AnalyticsDiff {
  return {
    impressionsDiffPercentage: String(calculatePercentageDifference(currentItem.impressions, previousItem.impressions)),
    clicksDiffPercentage: String(calculatePercentageDifference(currentItem.clicks, previousItem.clicks)),
    conversionsDiffPercentage: String(calculatePercentageDifference(currentItem.conversions, previousItem.conversions)),
    revenueDiffPercentage: String(calculatePercentageDifference(currentItem.revenue, previousItem.revenue)),
    cvrDiffPercentage:
      previousItem.cvr !== 0
        ? String((((currentItem.cvr - previousItem.cvr) / Math.abs(previousItem.cvr)) * 100).toFixed(0)) + "%"
        : "100%",
    ctrDiffPercentage: String(calculatePercentageDifference(currentItem.ctr, previousItem.ctr)),
    declinedDiffPercentage: String(calculatePercentageDifference(currentItem?.declined, previousItem.declined)),
  };
}

export const removeDecimals = (obj: AnalyticsRecord): AnalyticsRecord => {
  const newObj: AnalyticsRecord = { ...obj };

  for (let key in newObj) {
    if (typeof newObj[key] === "number") {
      newObj[key] = Math.floor(newObj[key] as number);
    }
  }
  return { ...newObj, revenue: +obj?.revenue?.toFixed(2), cvr: +obj?.cvr?.toFixed(2) };
};

export const getCalculatedData = (current: AnalyticsRecord[], previous: AnalyticsRecord[]): AnalyticsRecord[] => {
  if (!current.length && !previous.length) {
    return [];
  }

  if (current.length === 0) {
    let currentData = previous.map((currentItem: AnalyticsRecord, index: number) => ({
      ...currentItem,
      ctr: 0,
      cvr: 0,
      revenue: 0,
      conversions: 0,
      clicks: 0,
      impressions: 0,
      declined: 0,
      impressionsDiffPercentage: "-100%",
      clicksDiffPercentage: "-100%",
      conversionsDiffPercentage: "-100%",
      revenueDiffPercentage: "-100%",
      cvrDiffPercentage: "-100%",
      ctrDiffPercentage: "-100%",
      declinedDiffPercentage: "-100%",
    }));

    return currentData;
  }

  if (previous.length === 0) {
    let previousData = current.map((currentItem: AnalyticsRecord, index: number) => ({
      ...currentItem,
      cvr: currentItem.cvr,
      impressionsDiffPercentage: "100%",
      clicksDiffPercentage: "100%",
      conversionsDiffPercentage: "100%",
      revenueDiffPercentage: "100%",
      cvrDiffPercentage: "100%",
      ctrDiffPercentage: "100%",
      declinedDiffPercentage: "100%",
    }));

    return previousData.map(removeDecimals);
  }

  const resultWithPercentage = current.map(currentItem => {
    const previousItem = previous.find(prevItem => prevItem.baseId === currentItem.baseId);

    if (previousItem) {
      return {
        ...currentItem,
        ...calculateDifferences(currentItem, previousItem),
      };
    } else {
      return currentItem;
    }
  });

  return resultWithPercentage.map(removeDecimals);
};

export const removeHtmlElementsFromString = (val: string) => {
  let temporaryElement = document.createElement("div");

  temporaryElement.innerHTML = val;
  return temporaryElement.textContent || temporaryElement.innerText || "";
};

export const getDiscountText = (value: number, type: string): string => {
  if (value) {
    if (type === "PERCENTAGE") {
      return `${value}% discount`;
    } else {
      return `${value} discount`;
    }
  } else {
    return "No discount";
  }
};

export const handleRedirectDashboard = () => {
  try {
    const redirectUrl = sessionStorage.getItem("redirectUrl");

    if (redirectUrl) {
      window.open(redirectUrl, "_blank");
    }
  } catch (error) {
    console.log(error);
  }
};

export function extractDataFromProxy(proxy: { [x: string]: any }) {
  const data: LDFlagSet = {};

  for (const key of Object.keys(proxy)) {
    data[key] = proxy[key];
  }
  return data;
}

interface BreadcrumbFormatParams {
  page?: string;
  condition?: string;
  variant?: string;
}

export const generateBreadcrumbFormat = ({ page, condition, variant }: BreadcrumbFormatParams) => {
  return [PageNames[page as PageType], condition, variant].filter(Boolean).join(" > ");
};

export const sendPostMessage = (event: IFramePostMessageType, data?: any, otherData?: any) => {
  if (window.opener && window.top !== window) {
    window.opener.postMessage(
      {
        eventType: event,
        data,
        ...otherData,
      },
      "*",
    );
  }
};
